import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import { CoordinateSystem as BaseCoordinateSystem, CoordinateSystemSize } from '@profis-engineering/gl-model/components/coordinate-system';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { StrengthPropertyId } from '../../services/design.service';
import { MeshCache } from '../cache/mesh-cache';
import { StrengthModel, StrengthTooltipKey } from '../strength-gl-model';

export class StrengthCoordinateSystem extends BaseCoordinateSystem<
    StrengthModel,
    StrengthPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    StrengthTooltipKey,
    ''> {

    protected calculateCoordinateSystemOffset(): Vector3 {
        return Vector3.Zero();
    }

    protected calculateTransformNode(): TransformNode {
        return this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
    }

    protected calculateTransformNodeZ(): TransformNode {
        return this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
    }

    protected calculateTransformNodeY(): TransformNode {
        return this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
    }

    protected override ensureMeshInternal(): boolean {
        const coordinateSystemOffset = this.calculateCoordinateSystemOffset();
        const offsetY = this.model.baseMaterial.height / 2 + 1;

        // position
        this.meshX.position.set(coordinateSystemOffset.x, offsetY, coordinateSystemOffset.z);
        this.meshY.position.set(coordinateSystemOffset.x, offsetY, coordinateSystemOffset.z);
        this.meshZ.position.set(coordinateSystemOffset.x, offsetY, coordinateSystemOffset.z);
        
        // rotation
        this.meshX.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
        this.meshY.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
        this.meshZ.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
        
        return true;
    }

    protected calculateCoordinateSystemSize(): CoordinateSystemSize {
        const dashLength = 200;

        return {
            back: dashLength,
            right: dashLength,
            top: dashLength
        };
    }

    protected calculateTranslateOffsetY(): Vector3 {
        return Vector3.Zero();
    }
}
