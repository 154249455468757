import { Injectable } from '@angular/core';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Design, IDesignStateBase, IProperty, StateChange } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType, IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { TrackChanges } from '@profis-engineering/pe-ui-common/entities/track-changes';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ApiOptions, CancellationTokenSource } from '@profis-engineering/pe-ui-common/services/api.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { CantOpenDesignBecauseLockedByOtherUser, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import isEqual from 'lodash-es/isEqual';
import { Subject } from 'rxjs';
import { WritableKeys } from 'ts-essentials';
import { getSpriteAsIconStyle } from '../sprites';
import { Approval } from './approval.service';
import { ChangesService } from './changes.service';
import { CommonCodeListService } from './common-code-list.service';
import { ApiAppPropertyId, DataService, PropertyDetail, Region } from './data.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentService } from './document.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { SpApiService } from './sp-api.service';
import { TrackingDetails, TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';
import { InternalDesign } from './user.service';

export const enum PropertyInfoType {
    Unit = 1,
    Scalar = 2,
    CodeList = 3,
    Id = 4,
    String = 5,
    Boolean = 6,
    Custom = 7
}

interface PropertyInfoNumber {
    precision?: number;
}

interface PropertyInfoUnit extends PropertyInfoNumber {
    type: PropertyInfoType.Unit;
    unitGroup: UnitGroup;
}

interface PropertyInfoScalar extends PropertyInfoNumber {
    type: PropertyInfoType.Scalar;
}

interface PropertyInfoId {
    type: PropertyInfoType.Id;
    trackingName?: string;
}

interface PropertyInfoString {
    type: PropertyInfoType.String;
}

interface PropertyInfoBoolean {
    type: PropertyInfoType.Boolean;
}

interface PropertyInfoCustom {
    type: PropertyInfoType.Custom;
}

interface PropertyInfoCodeList {
    type: PropertyInfoType.CodeList;
    dataName: keyof DataService | keyof DataService['units'];
}

interface PropertyInfoBase {
    trackingName?: string;
}

export type PropertyInfo = PropertyInfoBase & (
    PropertyInfoUnit |
    PropertyInfoScalar |
    PropertyInfoCodeList |
    PropertyInfoString |
    PropertyInfoBoolean |
    PropertyInfoId |
    PropertyInfoCustom
);

export const propertyInfos: Record<PropertyId, PropertyInfo> = {
    designMethodName: { type: PropertyInfoType.String },

    gammaS: { type: PropertyInfoType.Scalar, trackingName: 'Gamma_s' },
    gammaC: { type: PropertyInfoType.Scalar, trackingName: 'Gamma_c' },
    alphaCC: { type: PropertyInfoType.Scalar, trackingName: 'Alpha_cc' },
    etaT: { type: PropertyInfoType.Scalar, trackingName: 'Eta_t' },
    e: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'SteelE' },
    kc: { type: PropertyInfoType.Scalar, trackingName: 'k_c'},

    unitLength: { type: PropertyInfoType.CodeList, dataName: 'lengthById' },
    unitArea: { type: PropertyInfoType.CodeList, dataName: 'areaById' },
    unitForce: { type: PropertyInfoType.CodeList, dataName: 'forceById' },
    unitStress: { type: PropertyInfoType.CodeList, dataName: 'stressById' },
    unitMoment: { type: PropertyInfoType.CodeList, dataName: 'momentById' },
    unitTemperature: { type: PropertyInfoType.CodeList, dataName: 'temperatureById' },
    unitForcePerLength: { type: PropertyInfoType.CodeList, dataName: 'forcePerLengthById' },
    unitDensity: { type: PropertyInfoType.CodeList, dataName: 'densityById' },
    unitAreaPerLength: { type: PropertyInfoType.CodeList, dataName: 'areaPerLengthById' },

    reportTemplateId: { type: PropertyInfoType.Id },
    reportTypeId: { type: PropertyInfoType.CodeList, dataName: 'reportTypesById' },
    reportFirstPage: { type: PropertyInfoType.Scalar },
    reportLanguageId: { type: PropertyInfoType.CodeList, dataName: 'languagesById' },
    reportPaperSizeId: { type: PropertyInfoType.CodeList, dataName: 'paperSizesById' },
    reportCompanyName: { type: PropertyInfoType.String },
    reportAddress: { type: PropertyInfoType.String },
    reportContactPerson: { type: PropertyInfoType.String },
    reportPhoneNumber: { type: PropertyInfoType.String },
    reportEmail: { type: PropertyInfoType.String },
    reportCustomPictures: { type: PropertyInfoType.Custom },
    reportNotes: { type: PropertyInfoType.String },
    reportStrengtheningApplication: { type: PropertyInfoType.String },
    designStandardId: { type: PropertyInfoType.CodeList, dataName: 'designStandardsById', trackingName: 'DESIGNSTANDARD' },
    fastenerFamilyGroupId: { type: PropertyInfoType.CodeList, dataName: 'fastenerFamilyGroupsById' },
    fastenerFamilyId: { type: PropertyInfoType.CodeList, dataName: 'fastenerFamiliesById' },
    fastenerId: { type: PropertyInfoType.CodeList, dataName: 'fastenersById', trackingName: 'PRODUCT_DIAMETER' },

    baseMaterialId: { type: PropertyInfoType.CodeList, dataName: 'baseMaterialsById', trackingName: 'CONCRETECLASS' },
    fcCube: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'f_c_cube' },
    fcCylinder: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'f_c_cyl' },
    shortTermTemperature: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Temperature, trackingName: 'TEMPSHORT' },
    longTermTemperature: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Temperature, trackingName: 'TEMPLONG' },
    shearStrutAngle: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Angle, trackingName: 'ShearStrutAngle' },
    loadTypeId: { type: PropertyInfoType.CodeList, dataName: 'loadTypesById', trackingName: 'LOADTYPE' },
    axialStress: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'AxialStress', precision: 2 },
    nationalAnnex: { type: PropertyInfoType.Boolean, trackingName: 'UseNationalAnnex' },
    postInstalledReinforcementDesignId: { type: PropertyInfoType.CodeList, dataName: 'postInstalledReinforcementDesignsById', trackingName: 'DesignOfPir' },

    reinforcementYieldStrength: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'ExrTensileYieldStrength' },

    drillingTypeId: { type: PropertyInfoType.CodeList, dataName: 'drillingTypesById', trackingName: 'DRILLINGMETHOD' },
    holeTypeId: { type: PropertyInfoType.CodeList, dataName: 'holeTypesById', trackingName: 'HOLETYPE' },
    drillingAidId: { type: PropertyInfoType.CodeList, dataName: 'drillingAidsById', trackingName: 'DrillingAid' },
    depthOfRecess: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'DepthOfRecess' },
    approval: { type: PropertyInfoType.Custom },
};

export const strengthPropertyInfos: Record<StrengthPropertyId, PropertyInfo> = {
    ...propertyInfos,

    definedTensionBars: { type: PropertyInfoType.Boolean },
    crossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area },
    effectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    loadCombinationName: { type: PropertyInfoType.String },
    loadCombinations: { type: PropertyInfoType.Custom },
    selectedLoadCombinationIndex: { type: PropertyInfoType.Scalar },
    withoutNutAndWasher: { type: PropertyInfoType.Boolean },

    zone1LengthLock: { type: PropertyInfoType.Boolean },
    zone2LengthLock: { type: PropertyInfoType.Boolean },
    zone3LengthLock: { type: PropertyInfoType.Boolean },
    zone4LengthLock: { type: PropertyInfoType.Boolean },

    concreteMemberId: { type: PropertyInfoType.CodeList, dataName: 'concreteMembersById', trackingName: 'ConcreteMember' },
    aggregateSizeId: { type: PropertyInfoType.CodeList, dataName: 'aggregateSizesById', trackingName: 'AggregateSize' },
    plasticStrain: { type: PropertyInfoType.Boolean, trackingName: 'UsePlasticStrain' },
    epsilonV: { type: PropertyInfoType.Scalar, trackingName: 'Epsilon_v', precision: 6 },
    slabWidth: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ConcreteSizeY' },
    slabLength: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ConcreteSizeX' },
    slabHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ConcreteSizeZ' },
    zonesNumberId: { type: PropertyInfoType.CodeList, dataName: 'zonesNumbersById', trackingName: 'NR_Zones' },

    zone1Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone1SizeX' },
    zone1Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone1SizeY' },
    zone2Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone2SizeX' },
    zone2Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone2SizeY' },
    zone3Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone3SizeX' },
    zone3Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone3SizeY' },
    zone4Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone4SizeX' },
    zone4Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone4SizeY' },
    zone5Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone5SizeX' },
    zone5Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone5SizeY' },
    zone6Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone6SizeX' },
    zone6Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone6SizeY' },
    zone7Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone7SizeX' },
    zone7Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone7SizeY' },
    zone8Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone8SizeX' },
    zone8Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Zone8SizeY' },

    defineOpening: { type: PropertyInfoType.Boolean },
    openingLength: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Opening1SizeX' },
    openingWidth: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Opening1SizeY' },
    openingOriginX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Opening1OriginX' },
    openingOriginY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'Opening1OriginY' },

    reinforcementYieldStrengthSia: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Stress, trackingName: 'ExrTensileYieldStrength' },

    zone1CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone1TotalArea' },
    zone1Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone1Cover' },
    zone1EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone1EffectiveHeight' },
    zone1DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone1Defined' },
    zone1ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone1Contribution' },
    zone2CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone2TotalArea' },
    zone2Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone2Cover' },
    zone2EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone2EffectiveHeight' },
    zone2DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone2Defined' },
    zone2ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone2Contribution' },
    zone3CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone3TotalArea' },
    zone3Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone3Cover' },
    zone3EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone3EffectiveHeight' },
    zone3DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone3Defined' },
    zone3ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone3Contribution' },
    zone4CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone4TotalArea' },
    zone4Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone4Cover' },
    zone4EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone4EffectiveHeight' },
    zone4DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone4Defined' },
    zone4ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone4Contribution' },
    zone5CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone5TotalArea' },
    zone5Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone5Cover' },
    zone5EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone5EffectiveHeight' },
    zone5DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone5Defined' },
    zone5ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone5Contribution' },
    zone6CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone6TotalArea' },
    zone6Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone6Cover' },
    zone6EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone6EffectiveHeight' },
    zone6DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone6Defined' },
    zone6ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone6Contribution' },
    zone7CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone7TotalArea' },
    zone7Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone7Cover' },
    zone7EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone7EffectiveHeight' },
    zone7DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone7Defined' },
    zone7ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone7Contribution' },
    zone8CrossSectionalArea: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area, trackingName: 'ExrTensileZone8TotalArea' },
    zone8Cover: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone8Cover' },
    zone8EffectiveHeight: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'ExrTensileZone8EffectiveHeight' },
    zone8DefineShearReinforcement: { type: PropertyInfoType.Boolean, trackingName: 'ExrShearZone8Defined' },
    zone8ReinforcementContribution: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Force, trackingName: 'ExrShearZone8Contribution' },

    reinforcementEffectiveness: { type: PropertyInfoType.Scalar, trackingName: 'ExrShearEffectiveness' },

    zone1StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone1Defined' },
    zone1InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone1InstallDirection' },
    zone1SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1SpacingX' },
    zone1SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1SpacingY' },
    zone1MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1MinEdgeDistX' },
    zone1MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1MinEdgeDistY' },
    zone1TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1TransverseEcc' },
    zone2StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone2Defined' },
    zone2InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone2InstallDirection' },
    zone2SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2SpacingX' },
    zone2SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2SpacingY' },
    zone2MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2MinEdgeDistX' },
    zone2MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2MinEdgeDistY' },
    zone2TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2TransverseEcc' },
    zone3StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone3Defined' },
    zone3InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone3InstallDirection' },
    zone3SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3SpacingX' },
    zone3SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3SpacingY' },
    zone3MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3MinEdgeDistX' },
    zone3MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3MinEdgeDistY' },
    zone3TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3TransverseEcc' },
    zone4StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone4Defined' },
    zone4InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone4InstallDirection' },
    zone4SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4SpacingX' },
    zone4SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4SpacingY' },
    zone4MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4MinEdgeDistX' },
    zone4MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4MinEdgeDistY' },
    zone4TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4TransverseEcc' },
    zone5StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone5Defined' },
    zone5InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone5InstallDirection' },
    zone5SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5SpacingX' },
    zone5SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5SpacingY' },
    zone5MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5MinEdgeDistX' },
    zone5MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5MinEdgeDistY' },
    zone5TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5TransverseEcc' },
    zone6StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone6Defined' },
    zone6InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone6InstallDirection' },
    zone6SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6SpacingX' },
    zone6SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6SpacingY' },
    zone6MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6MinEdgeDistX' },
    zone6MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6MinEdgeDistY' },
    zone6TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6TransverseEcc' },
    zone7StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone7Defined' },
    zone7InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone7InstallDirection' },
    zone7SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7SpacingX' },
    zone7SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7SpacingY' },
    zone7MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7MinEdgeDistX' },
    zone7MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7MinEdgeDistY' },
    zone7TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7TransverseEcc' },
    zone8StrengtheningElementDefinition: { type: PropertyInfoType.Boolean, trackingName: 'PirZone8Defined' },
    zone8InstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirectionsById', trackingName: 'PirZone8InstallDirection' },
    zone8SpacingX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8SpacingX' },
    zone8SpacingY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8SpacingY' },
    zone8MinimumEdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8MinEdgeDistX' },
    zone8MinimumEdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8MinEdgeDistY' },
    zone8TransverseEccentricity: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8TransverseEcc' },

    zone1EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1EdgeDistX' },
    zone1EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone1EdgeDistY' },
    zone2EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2EdgeDistX' },
    zone2EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone2EdgeDistY' },
    zone3EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3EdgeDistX' },
    zone3EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone3EdgeDistY' },
    zone4EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4EdgeDistX' },
    zone4EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone4EdgeDistY' },
    zone5EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5EdgeDistX' },
    zone5EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone5EdgeDistY' },
    zone6EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6EdgeDistX' },
    zone6EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone6EdgeDistY' },
    zone7EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7EdgeDistX' },
    zone7EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone7EdgeDistY' },
    zone8EdgeDistanceX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8EdgeDistX' },
    zone8EdgeDistanceY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length, trackingName: 'PirZone8EdgeDistY' }
};

export const punchPropertyInfos: Record<PunchPropertyId, PropertyInfo> = {
    ...propertyInfos,

    compressionMemberId: { type: PropertyInfoType.CodeList, dataName: 'compressionMembersById' },
    baseMemberId: { type: PropertyInfoType.CodeList, dataName: 'baseMembersById' },
    punchLength: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchWidth: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    spanNegX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    spanPosX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    spanNegY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    spanPosY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    thickness: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    betaId: { type: PropertyInfoType.CodeList, dataName: 'betaValuesById', trackingName: 'BetaId' },
    beta: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.None, trackingName: 'Beta', precision: 1 },
    crossSectionalAreaX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area },
    coverX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    effectiveHeightX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    crossSectionalAreaY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Area },
    coverY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    effectiveHeightY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    defineStrengtheningElement: { type: PropertyInfoType.Boolean },
    radiiX: { type: PropertyInfoType.Scalar },
    radiiY: { type: PropertyInfoType.Scalar },
    radiiCorners: { type: PropertyInfoType.Scalar },
    firstPerimeterSpacing: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    subsequentPerimeterSpacing: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    radiiElementNumber: { type: PropertyInfoType.Scalar },
    punchInstallationDirectionId: { type: PropertyInfoType.CodeList, dataName: 'installationDirections' },
    reinforcementArrangementId: { type: PropertyInfoType.CodeList, dataName: 'reinforcementArrangements' },
    loadCombinationName: { type: PropertyInfoType.String },
    loadCombinations: { type: PropertyInfoType.Custom },
    selectedLoadCombinationIndex: { type: PropertyInfoType.Scalar },
    openingsNumberId: { type: PropertyInfoType.CodeList, dataName: 'openingsNumbersById' },
    punchOpening1Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening2Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening3Length: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening1Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening2Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening3Width: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening1OriginX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening2OriginX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening3OriginX: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening1OriginY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening2OriginY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
    punchOpening3OriginY: { type: PropertyInfoType.Unit, unitGroup: UnitGroup.Length },
};

export interface Properties {
    readonly designStandardId: number;
    readonly designMethodName: string | null;

    alphaCC: number | null;
    etaT: number;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    kc: number | null;

    unitLength: UnitType;
    unitArea: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitTemperature: UnitType;
    unitForcePerLength: UnitType;
    unitDensity: UnitType;
    unitAreaPerLength: UnitType;

    /** null = Default, 0 = Custom, other = Specific or Default if not found */
    reportTemplateId: number | null;
    reportTypeId: number | null;
    reportFirstPage: number | null;
    reportLanguageId: number | null;
    reportPaperSizeId: number | null;
    reportCompanyName: string | null;
    reportAddress: string | null;
    reportContactPerson: string | null;
    reportPhoneNumber: string | null;
    reportEmail: string | null;
    reportCustomPictures: string[];
    reportNotes: string | null;
    reportStrengtheningApplication: string | null;
    fastenerFamilyGroupId: number;
    fastenerFamilyId: number;
    fastenerId: number;

    baseMaterialId: number;
    fcCube: number;
    fcCylinder: number;
    shortTermTemperature: number;
    longTermTemperature: number;
    shearStrutAngle: number;
    loadTypeId: number;
    axialStress: number;
    nationalAnnex: number;
    postInstalledReinforcementDesignId: number;

    reinforcementYieldStrength: number;
    drillingTypeId: number;
    holeTypeId: number;
    drillingAidId: number;
    depthOfRecess: number;

    readonly approval?: Approval;
}

export type AllPropertyIds = StrengthPropertyId | PunchPropertyId;
export type PropertyId = keyof Properties;
export type PropertyDetails = Record<keyof Properties, PropertyDetail>;

export interface UnitProperties {
    unitLength?: UnitType;
    unitArea?: UnitType;
    unitStress?: UnitType;
    unitForce?: UnitType;
    unitMoment?: UnitType;
    unitTemperature?: UnitType;
    unitForcePerLength?: UnitType;
    unitDensity?: UnitType;
    unitAreaPerLength?: UnitType;
}

export interface StrengthProperties extends Properties {
    readonly zone1EdgeDistanceX: number | null;
    readonly zone1EdgeDistanceY: number | null;
    readonly zone2EdgeDistanceX: number | null;
    readonly zone2EdgeDistanceY: number | null;
    readonly zone3EdgeDistanceX: number | null;
    readonly zone3EdgeDistanceY: number | null;
    readonly zone4EdgeDistanceX: number | null;
    readonly zone4EdgeDistanceY: number | null;
    readonly zone5EdgeDistanceX: number | null;
    readonly zone5EdgeDistanceY: number | null;
    readonly zone6EdgeDistanceX: number | null;
    readonly zone6EdgeDistanceY: number | null;
    readonly zone7EdgeDistanceX: number | null;
    readonly zone7EdgeDistanceY: number | null;
    readonly zone8EdgeDistanceX: number | null;
    readonly zone8EdgeDistanceY: number | null;

    concreteMemberId: number;
    slabWidth: number;
    slabLength: number;
    slabHeight: number;
    zonesNumberId: number;
    plasticStrain: boolean;
    epsilonV: number;
    reinforcementYieldStrengthSia: number;
    definedTensionBars: number;
    crossSectionalArea: number;
    effectiveHeight: number;
    zone1StrengtheningElementDefinition: boolean;
    zone1InstallationDirectionId: number | null;
    zone1SpacingX: number | null;
    zone1SpacingY: number | null;
    zone1MinimumEdgeDistanceX: number | null;
    zone1MinimumEdgeDistanceY: number | null;
    zone1Length: number;
    zone1Width: number | null;
    zone1TransverseEccentricity: number | null;
    zone1CrossSectionalArea: number | null;
    zone1Cover: number | null;
    zone1DefineShearReinforcement: boolean;
    zone1ReinforcementContribution: number | null;
    zone1LengthLock: number;
    zone1EffectiveHeight: number | null;
    zone2StrengtheningElementDefinition: boolean | null;
    zone2InstallationDirectionId: number | null;
    zone2SpacingX: number | null;
    zone2SpacingY: number | null;
    zone2MinimumEdgeDistanceX: number | null;
    zone2MinimumEdgeDistanceY: number | null;
    zone2Length: number | null;
    zone2Width: number | null;
    zone2TransverseEccentricity: number | null;
    zone2CrossSectionalArea: number | null;
    zone2Cover: number | null;
    zone2DefineShearReinforcement: boolean;
    zone2ReinforcementContribution: number | null;
    zone2EffectiveHeight: number | null;
    zone2LengthLock: number | null;
    zone3StrengtheningElementDefinition: boolean | null;
    zone3InstallationDirectionId: number | null;
    zone3SpacingX: number | null;
    zone3SpacingY: number | null;
    zone3MinimumEdgeDistanceX: number | null;
    zone3MinimumEdgeDistanceY: number | null;
    zone3Length: number | null;
    zone3Width: number | null;
    zone3TransverseEccentricity: number | null;
    zone3CrossSectionalArea: number | null;
    zone3Cover: number | null;
    zone3DefineShearReinforcement: boolean;
    zone3ReinforcementContribution: number | null;
    zone3EffectiveHeight: number | null;
    zone3LengthLock: number | null;
    zone4StrengtheningElementDefinition: boolean | null;
    zone4InstallationDirectionId: number | null;
    zone4SpacingX: number | null;
    zone4SpacingY: number | null;
    zone4MinimumEdgeDistanceX: number | null;
    zone4MinimumEdgeDistanceY: number | null;
    zone4Length: number | null;
    zone4Width: number | null;
    zone4TransverseEccentricity: number | null;
    zone4CrossSectionalArea: number | null;
    zone4Cover: number | null;
    zone4DefineShearReinforcement: boolean;
    zone4ReinforcementContribution: number | null;
    zone4EffectiveHeight: number | null;
    zone4LengthLock: number | null;
    zone5StrengtheningElementDefinition: boolean;
    zone5InstallationDirectionId: number | null;
    zone5SpacingX: number | null;
    zone5SpacingY: number | null;
    zone5MinimumEdgeDistanceX: number | null;
    zone5MinimumEdgeDistanceY: number | null;
    zone5CrossSectionalArea: number | null;
    zone5Cover: number | null;
    zone5DefineShearReinforcement: boolean;
    zone5ReinforcementContribution: number | null;
    zone5EffectiveHeight: number | null;
    zone5Length: number | null;
    zone5Width: number | null;
    zone5TransverseEccentricity: number | null;
    zone6StrengtheningElementDefinition: boolean;
    zone6InstallationDirectionId: number | null;
    zone6SpacingX: number | null;
    zone6SpacingY: number | null;
    zone6MinimumEdgeDistanceX: number | null;
    zone6MinimumEdgeDistanceY: number | null;
    zone6Length: number | null;
    zone6Width: number | null;
    zone6TransverseEccentricity: number | null;
    zone6CrossSectionalArea: number | null;
    zone6Cover: number | null;
    zone6DefineShearReinforcement: boolean;
    zone6ReinforcementContribution: number | null;
    zone6EffectiveHeight: number | null;
    zone7StrengtheningElementDefinition: boolean;
    zone7InstallationDirectionId: number | null;
    zone7SpacingX: number | null;
    zone7SpacingY: number | null;
    zone7MinimumEdgeDistanceX: number | null;
    zone7MinimumEdgeDistanceY: number | null;
    zone7Length: number | null;
    zone7Width: number | null;
    zone7TransverseEccentricity: number | null;
    zone7CrossSectionalArea: number | null;
    zone7Cover: number | null;
    zone7DefineShearReinforcement: boolean;
    zone7ReinforcementContribution: number | null;
    zone7EffectiveHeight: number | null;
    zone8StrengtheningElementDefinition: boolean;
    zone8InstallationDirectionId: number | null;
    zone8SpacingX: number | null;
    zone8SpacingY: number | null;
    zone8MinimumEdgeDistanceX: number | null;
    zone8MinimumEdgeDistanceY: number | null;
    zone8Length: number | null;
    zone8Width: number | null;
    zone8TransverseEccentricity: number | null;
    zone8CrossSectionalArea: number | null;
    zone8Cover: number | null;
    zone8DefineShearReinforcement: boolean;
    zone8ReinforcementContribution: number | null;
    zone8EffectiveHeight: number | null;
    reinforcementEffectiveness: number | null;
    aggregateSizeId: number;
    loadCombinationName: string | null;
    withoutNutAndWasher: boolean;
    loadCombinations: LoadCombination[];
    selectedLoadCombinationIndex: number;
    defineOpening: boolean;
    openingLength: number;
    openingWidth: number;
    openingOriginX: number;
    openingOriginY: number;
}

export type StrengthPropertyId = keyof StrengthProperties;
export type StrengthWritableProperties = Pick<StrengthProperties, WritableKeys<StrengthProperties>>;
export type StrengthWritablePropertyId = keyof StrengthWritableProperties;
export type StrengthPropertyDetails = Record<keyof StrengthProperties, PropertyDetail>;

export type StrengthPropertyIdValueType = {
    [P in StrengthWritablePropertyId]: {
        propertyId: P;
        propertyValue: StrengthWritableProperties[P];
    };
};
export type StrengthPropertyIdValue = {
    [P in StrengthWritablePropertyId]: {
        propertyId: P;
        propertyValue: StrengthWritableProperties[P];
    };
}[StrengthWritablePropertyId];

export interface PunchProperties extends Properties {
    compressionMemberId: number;
    baseMemberId: number;
    punchLength: number;
    punchWidth: number;
    spanNegX: number;
    spanPosX: number;
    spanNegY: number;
    spanPosY: number;
    thickness: number;
    betaId: number | null;
    beta: number | null;
    crossSectionalAreaX: number;
    coverX: number;
    effectiveHeightX: number;
    crossSectionalAreaY: number;
    coverY: number;
    effectiveHeightY: number;
    defineStrengtheningElement: boolean;
    radiiX: number;
    radiiY: number;
    radiiCorners: number;
    firstPerimeterSpacing: number;
    subsequentPerimeterSpacing: number;
    radiiElementNumber: number;
    punchInstallationDirectionId: number;
    reinforcementArrangementId: number;
    loadCombinationName: string | null;
    loadCombinations: PunchLoadCombination[];
    selectedLoadCombinationIndex: number;
    openingsNumberId: number;
    punchOpening1Length: number | null;
    punchOpening2Length: number | null;
    punchOpening3Length: number | null;
    punchOpening1Width: number | null;
    punchOpening2Width: number | null;
    punchOpening3Width: number | null;
    punchOpening1OriginX: number | null;
    punchOpening2OriginX: number | null;
    punchOpening3OriginX: number | null;
    punchOpening1OriginY: number | null;
    punchOpening2OriginY: number | null;
    punchOpening3OriginY: number | null;
}

export type PunchPropertyId = keyof PunchProperties;
export type PunchWritableProperties = Pick<PunchProperties, WritableKeys<PunchProperties>>;
export type PunchWritablePropertyId = keyof PunchWritableProperties;
export type PunchPropertyDetails = Record<keyof PunchProperties, PropertyDetail>;

export type PunchPropertyIdValueType = {
    [P in PunchWritablePropertyId]: {
        propertyId: P;
        propertyValue: PunchWritableProperties[P];
    };
};
export type PunchPropertyIdValue = {
    [P in PunchWritablePropertyId]: {
        propertyId: P;
        propertyValue: PunchWritableProperties[P];
    };
}[PunchWritablePropertyId];

export interface PropertyIdValue {
    propertyId: string;
    propertyValue: unknown;
}

export interface PropertyIdValue {
    propertyId: string;
    propertyValue: unknown;
}
export type PropertyIdValueType = Record<string, PropertyIdValue>;

export interface DesignDetailsData {
    regionId: number;
    designTypeId: DesignTypeId;
    properties: Properties;
    propertyDetails: PropertyDetails;
}

export interface StrengthDesignDetailsData extends DesignDetailsData {
    designTypeId: DesignTypeStrengthId;
    properties: StrengthProperties;
    propertyDetails: StrengthPropertyDetails;
}

export interface PunchDesignDetailsData extends DesignDetailsData {
    designTypeId: DesignTypePunchId;
    properties: PunchProperties;
    propertyDetails: PunchPropertyDetails;
}

export interface DesignDetails {
    properties: Properties;
    propertyDetails: PropertyDetails;
    regionId: number;
    designTypeId: DesignTypeId;
    /** -- DO NOT USE THIS -- Only used for design file (import and export). You probably want data from .properties property. */
    projectDesign: ProjectDesign;
    isTemplate: boolean;
    /** is undefined for template - check isTemplate property */
    designId: string | undefined;
    /** is undefined for template - check isTemplate property */
    designName: string | undefined;
    /** is undefined for template - check isTemplate property */
    projectId: string | undefined;
    /** is undefined for template - check isTemplate property */
    projectName: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateId: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateName: string | undefined;
    /** is undefined for design - check isTemplate property - is null when template project is root */
    templateProjectId: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateProjectName: string | undefined;
    region: Region;
    commonRegion: CommonRegion;
    designType: DesignType;

    calculateResult?: CalculationResult;
}

export interface StrengthDesignDetails extends DesignDetails {
    designTypeId: DesignTypeStrengthId;
    properties: StrengthProperties;
    propertyDetails: StrengthPropertyDetails;
    /** -- DO NOT USE THIS -- Only used for design file (import and export). You probably want data from .properties property. */
    projectDesign: StrengthProjectDesign;
    calculateResult?: StrengthCalculationResult;
}

export interface PunchDesignDetails extends DesignDetails {
    designTypeId: DesignTypePunchId;
    properties: PunchProperties;
    propertyDetails: PunchPropertyDetails;
    /** -- DO NOT USE THIS -- Only used for design file (import and export). You probably want data from .properties property. */
    projectDesign: PunchProjectDesign;
    calculateResult?: PunchCalculationResult;
}

export type DesignTypeStrengthId = 110;
export type DesignTypePunchId = 111;
export type DesignTypeId = DesignTypeStrengthId | DesignTypePunchId;

export type DesignTypeStrengthName = 'strength';
export type DesignTypePunchName = 'punch';
export type DesignTypeName = DesignTypeStrengthName | DesignTypePunchName;

export type DesignTypeStrength = typeof designTypes['strength'];
export type DesignTypePunch = typeof designTypes['punch'];
export type DesignType = DesignTypeStrength | DesignTypePunch;

export const designTypes = {
    strength: {
        id: 110,
        name: 'strength',
        nameKey: 'SP.DesignList.Strength.DesignType'
    },
    punch: {
        id: 111,
        name: 'punch',
        nameKey: 'SP.DesignList.Punch.DesignType'
    }
} satisfies Record<DesignTypeName, {
    id: DesignTypeId;
    name: DesignTypeName;
    nameKey: string;
}>;

export const designTypesById = Object.fromEntries(Object.entries(designTypes).map(([, designTypeDetails]) => [designTypeDetails.id, designTypeDetails])) as unknown as Record<DesignTypeId, DesignType>;

export interface ProjectDesign {
    designTypeId: DesignTypeId;
    regionId: number;
    designStandardId: number;
}

export interface StrengthProjectDesign extends ProjectDesign {
    designTypeId: DesignTypeStrengthId;
}

export interface PunchProjectDesign extends ProjectDesign {
    designTypeId: DesignTypePunchId;
}

export interface DesignConvertResult {
    projectDesign?: ProjectDesign;
    invalidDesignMessageKey?: string;
}

export interface LoadCombination {
    loadCombinationName?: string;
    loadTypeId: number;
    zoneLoads: ZoneLoad[];
}

export interface PunchLoadCombination {
    loadCombinationName?: string;
    loadTypeId: number;
    ved: number;
    medX: number;
    medY: number;
}

export enum LoadType {
    Unknown = 0,
    Static = 1,
    Seismic = 2,
    Fatigue = 3,
    Fire = 4
}

export enum BetaType {
    Unknown = 0,
    Approximated = 1,
    UserDefined = 2,
    Refined = 3
}

export enum ConfirmationType {
    Confirmation = 0,
    Information = 1,
    Custom = 2
}

// TODO TEAM: create some base inertfaces so we don't copy paste properties

export interface CreateDesignOptions {
    projectId: string;
    designName: string;

    designTypeId: DesignTypeId;
    regionId: number;
    designStandardId: number;
    reportLanguageId: number;

    unitLength: UnitType;
    unitArea: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitTemperature: UnitType;
    unitForcePerLength: UnitType;
    unitDensity: UnitType;
    unitAreaPerLength: UnitType;
}

export interface StrengthCreateDesignOptions extends CreateDesignOptions {
    designTypeId: DesignTypeStrengthId;
    alphaCC: number | null;
    etaT: number;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    Kc: number | null;
}

export interface PunchCreateDesignOptions extends CreateDesignOptions {
    designTypeId: DesignTypePunchId;
    alphaCC: number | null;
    etaT: number;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    Kc: number | null;
}

export interface CreateDesignFromProjectDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designName: string;
}

export interface StrengthCreateDesignFromProjectDesignOptions extends CreateDesignFromProjectDesignOptions {
    projectDesign: StrengthProjectDesign;
}

export interface PunchCreateDesignFromProjectDesignOptions extends CreateDesignFromProjectDesignOptions {
    projectDesign: PunchProjectDesign;
}

export interface DesignServiceCreateDesignOptions {
    regionId: number;
    designTypeId: DesignTypeId;
    designStandardId: number;
    reportLanguageId: number;

    unitLength: UnitType;
    unitArea: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitTemperature: UnitType;
    unitForcePerLength: UnitType;
    unitDensity: UnitType;
    unitAreaPerLength: UnitType;
}

export interface StrengthDesignServiceCreateDesignOptions extends DesignServiceCreateDesignOptions {
    designTypeId: DesignTypeStrengthId;
    alphaCC: number | null;
    etaT: number | null;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    Kc: number | null;
}

export interface PunchDesignServiceCreateDesignOptions extends DesignServiceCreateDesignOptions {
    designTypeId: DesignTypePunchId;
    alphaCC: number | null;
    etaT: number | null;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    Kc: number | null;
}

export interface DocumentServiceCreateDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designName: string;
    regionId: number;
    designStandardId: number;
    designTypeId: DesignTypeId;
}

export interface LocalizationOptions {
    language: string;
    numberDecimalSeparator: string;
    numberGroupSeparator: string;
    numberGroupSizes: number[];
    globalRegionShortDatePattern?: string;
}

export interface TemplateOptions {
    headerText?: string;
    footerText?: string;
    userLogoId?: number;
    company?: string;
    address?: string;
    phone?: string;
    fax?: string;
    specifier?: string;
    email?: string;
    excludeCompanyDetails: boolean;
}

export enum CalculationStatus {
    OK = 1,
    OutOfScope = 2,
    Invalid = 3,
}

export interface CalculationResult {
    calculationStatus: CalculationStatus;
    isDesignValid: boolean;
    scopeCheckResults: ScopeCheckResults;
    kernelResult?: KernelResult;
    calculateDuration: CalculateDuration;
}

export interface CalculateDuration {
    preCalculationScopeChecks: number;
    kernelCalculation: number;
    postCalculationScopeChecks: number;
}

export type KernelResult = unknown;

export interface StrengthCalculationResult extends CalculationResult {
    /** @deprecated TODO TEAM: refactor utilizations to generic implementation (see Punching) */
    utilizationResults: UtilizationResults[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PunchCalculationResult extends CalculationResult {
    // TODO TEAM: add strength results and remove eslint suppress
}

export interface ScopeCheckResults {
    failedScopeChecks: ScopeCheckResult[];
}

export interface ScopeCheckResult {
    isValid: boolean;
    severity: ScopeCheckSeverity;
    message: TranslationFormat;
    name: string;
}

export interface UtilizationResults {
    zoneId: string;
    utilizationItems: UtilizationItem[];
}

export interface UtilizationItem {
    utilizationType: UtilizationType;
    utilizationValue: UtilizationValue;
    actualValue: number;
    actualStrengtheningNeeded: boolean;
    isPercentage: boolean;
}

export interface ConstantParameter extends TranslationParameter {
    value: string;
}

export interface NoneUnitNumericalParameter extends TranslationParameter {
    additionalPrecision?: number;
    value?: number;
}

export interface NumericalParameter extends TranslationParameter {
    additionalPrecision?: number;
    unitGroup: UnitGroup;
    value?: number;
}

export interface TemplateParameter extends TranslationParameter {
    value: TranslationFormat;
}

export interface TranslatableParameter extends TranslationParameter {
    value: string;
}

export interface TranslationFormat {
    template?: string;
    templateFormat?: TranslationFormat;
    translationParameters: TranslationParameter[];
}

export interface TranslationParameter {
    name: string;
    parameterType: TranslationParameterType;
}

export const enum TranslationParameterType {
    None = 0,
    Numerical = 1,
    Translatable = 2,
    Constant = 3,
    Template = 4,
    NoneUnitNumericalParameter = 5
}

export enum ScopeCheckSeverity {
    Info,
    Error,
    Critical
}

export interface CreateDesignDetailsOptions {
    projectDesign: ProjectDesign;
    designDetailsData: DesignDetailsData;
    calculationResult?: CalculationResult;

    projectId?: string;
    projectName?: string;
    designId?: string;
    designName?: string;

    /** is null when template project is root */
    templateProjectId?: string;
    templateProjectName?: string;
    templateId?: string;
    templateName?: string;
}

export interface StrengthCreateDesignDetailsOptions extends CreateDesignDetailsOptions {
    projectDesign: StrengthProjectDesign;
    designDetailsData: StrengthDesignDetailsData;
    calculationResult?: StrengthCalculationResult;
}

export interface PunchCreateDesignDetailsOptions extends CreateDesignDetailsOptions {
    projectDesign: PunchProjectDesign;
    designDetailsData: PunchDesignDetailsData;
    calculationResult?: PunchCalculationResult;
}

export interface UpdateDesignOptions {
    projectId: string;
    designId: string;
    designName: string;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface StrengthUpdateDesignOptions extends UpdateDesignOptions {
    projectDesign: StrengthProjectDesign;
    properties: StrengthPropertyIdValue[];
}

export interface PunchUpdateDesignOptions extends UpdateDesignOptions {
    projectDesign: PunchProjectDesign;
    properties: PunchPropertyIdValue[];
}

export interface UpdateDesignOrDesignTemplateOptions {
    designId: string | undefined;
    designName: string | undefined;
    projectId: string | undefined;

    templateId: string | undefined;
    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface StrengthUpdateDesignOrDesignTemplateOptions extends UpdateDesignOrDesignTemplateOptions {
    projectDesign: StrengthProjectDesign;
    properties: StrengthPropertyIdValue[];
}

export interface PunchUpdateDesignOrDesignTemplateOptions extends UpdateDesignOrDesignTemplateOptions {
    projectDesign: PunchProjectDesign;
    properties: PunchPropertyIdValue[];
}

export interface UpdateDesignFromProjectDesignOptions {
    designId: string;
    designName: string;
    projectId: string;
    projectDesign: ProjectDesign;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface StrengthUpdateDesignFromProjectDesignOptions extends UpdateDesignFromProjectDesignOptions {
    projectDesign: StrengthProjectDesign;
}

export interface PunchUpdateDesignFromProjectDesignOptions extends UpdateDesignFromProjectDesignOptions {
    projectDesign: PunchProjectDesign;
}

export interface DesignServiceUpdateDesignOptions {
    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];
}

export interface StrengthDesignServiceUpdateDesignOptions extends DesignServiceUpdateDesignOptions {
    projectDesign: StrengthProjectDesign;
    properties: StrengthPropertyIdValue[];
}

export interface PunchDesignServiceUpdateDesignOptions extends DesignServiceUpdateDesignOptions {
    projectDesign: PunchProjectDesign;
    properties: PunchPropertyIdValue[];
}

export interface UploadCustomImageResponse {
    imageName: string;
}

export interface UploadCustomImage {
    imageDataUri: string;
}

export interface CustomPictureData {
    imageKeys: string[];
}


export interface DocumentServiceUpdateDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designId: string;
    designName: string;
    regionId: number;
    designStandardId: number;
    designTypeId: DesignTypeId;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface TrackOnDesignChangeOptions {
    trackingDetails: TrackingDetails;
    designDetails: DesignDetails;

    /**
     * - true: call tracking immediately and return a Promise that awaits the tracking request
     * - false: call tracking in the background with debounce and return a Promise that does NOT await the tracking request
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceUpdateDesignOrDesignTemplateOptions {
    designId: string | undefined;
    designName: string | undefined;
    projectId: string | undefined;

    templateId: string | undefined;
    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    regionId: number;
    designStandardId: number;
    designTypeId: DesignTypeId;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface OpenDesignOptions {
    designId: string;
}

export interface OpenDesignTemplateOptions {
    designTemplateId: string;
}

export interface UpdateDesignImageOptions {
    designId: string;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface UpdateDesignImageOrTemplateDesignImageOptions {
    designId: string | undefined;
    templateId: string | undefined;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceUpdateDesignTemplateOptions {
    templateId: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;
    templateName: string;
    designTypeId: DesignTypeId;
    regionId: number;
    projectDesign: ProjectDesign;
    designStandardId: number;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceCreateDesignTemplateOptions {
    projectDesign: ProjectDesign;
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;
    designTypeId: DesignTypeId;
    regionId: number;
    designStandardId: number;
}

export interface StrengthDocumentServiceCreateDesignTemplateOptions {
    projectDesign: StrengthProjectDesign;
}

export interface PunchDocumentServiceCreateDesignTemplateOptions {
    projectDesign: PunchProjectDesign;
}

export interface CreateDesignTemplateOptions extends Omit<CreateDesignOptions, 'designName' | 'projectId'> {
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;
}

export interface StrengthCreateDesignTemplateOptions extends Omit<CreateDesignTemplateOptions & StrengthCreateDesignOptions, 'designName' | 'projectId'> {
    designTypeId: DesignTypeStrengthId;
}

export interface PunchCreateDesignTemplateOptions extends Omit<CreateDesignTemplateOptions & PunchCreateDesignOptions, 'designName' | 'projectId'> {
    designTypeId: DesignTypePunchId;
}

export interface CreateDesignOrDesignTemplateOptions extends Omit<CreateDesignOptions & CreateDesignTemplateOptions, 'designName' | 'projectId' | 'templateName' | 'templateProjectId'> {
    designName: string | undefined;
    projectId: string | undefined;

    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;
}

export interface StrengthCreateDesignOrDesignTemplateOptions extends Omit<StrengthCreateDesignOptions & StrengthCreateDesignTemplateOptions, 'designName' | 'projectId' | 'templateName' | 'templateProjectId'> {
    designName: string | undefined;
    projectId: string | undefined;

    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;
}

export interface PunchCreateDesignOrDesignTemplateOptions extends Omit<PunchCreateDesignOptions & PunchCreateDesignTemplateOptions, 'designName' | 'projectId' | 'templateName' | 'templateProjectId'> {
    designName: string | undefined;
    projectId: string | undefined;

    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;
}

export interface UpdateDesignTemplateOptions {
    templateId: string;
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface StrengthUpdateDesignTemplateOptions extends UpdateDesignTemplateOptions {
    projectDesign: StrengthProjectDesign;
    properties: StrengthPropertyIdValue[];
}

export interface PunchUpdateDesignTemplateOptions extends UpdateDesignTemplateOptions {
    projectDesign: PunchProjectDesign;
    properties: PunchPropertyIdValue[];
}

export interface UpdateDesignTemplateImageOptions {
    templateId: string;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface CreateDesignFromTemplateOptions {
    templateId: string;
    designName: string | undefined;
    projectId: string | undefined;
}

export interface CloseDesignOptions {
    designId: string;
    designTypeId: DesignTypeId;
}

export interface CloseDesignTemplateOptions {
    templateId: string;
    designTypeId: DesignTypeId;
}

export interface CloseDesignOrDesignTemplateOptions {
    designId: string | undefined;
    templateId: string | undefined;
    designTypeId: DesignTypeId;
}

export interface ApiDesignCreateRequest {
    regionId: number;
    designTypeId: DesignTypeId;
    designStandardId: number;
    reportLanguageId: number;

    alphaCC: number | null;
    etaT: number | null;
    e: number | null;
    gammaS: number | null;
    gammaC: number | null;
    Kc: number | null;

    unitLength: UnitType;
    unitArea: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitTemperature: UnitType;
    unitForcePerLength: UnitType;
    unitDensity: UnitType;
    unitAreaPerLength: UnitType;
}

export interface StrengthApiDesignCreateRequest extends ApiDesignCreateRequest {
    designTypeId: DesignTypeStrengthId;
}

export interface PunchApiDesignCreateRequest extends ApiDesignCreateRequest {
    designTypeId: DesignTypePunchId;
}

export interface DesignUpdateRequest {
    projectDesign: ProjectDesign;
    properties: ApiUpdateDesignProperty[];
}

export interface StrengthDesignUpdateRequest extends DesignUpdateRequest {
    projectDesign: StrengthProjectDesign;
    properties: StrengthApiUpdateDesignProperty[];
}

export interface PunchDesignUpdateRequest extends DesignUpdateRequest {
    projectDesign: PunchProjectDesign;
    properties: PunchApiUpdateDesignProperty[];
}

export interface ApiDesignUpdateRequest {
    projectDesign: ProjectDesign;
    properties: ApiUpdateDesignProperty[];
    confirmed?: boolean;
}

export interface StrengthApiDesignUpdateRequest extends ApiDesignUpdateRequest {
    projectDesign: StrengthProjectDesign;
    properties: StrengthApiUpdateDesignProperty[];
}

export interface PunchApiDesignUpdateRequest extends ApiDesignUpdateRequest {
    projectDesign: PunchProjectDesign;
    properties: PunchApiUpdateDesignProperty[];
}

export interface ApiDesignUpdateResponse {
    projectDesign?: ProjectDesign;
    requiresConfirmation?: RequiresConfirmation;
}

export interface StrengthApiDesignUpdateResponse extends ApiDesignUpdateResponse {
    projectDesign?: StrengthProjectDesign;
}

export interface PunchApiDesignUpdateResponse extends ApiDesignUpdateResponse {
    projectDesign?: PunchProjectDesign;
}

export interface RequiresConfirmation {
    messageKeys: string[];
    confirmationType: ConfirmationType;
    customName?: string;
}

export interface UpdateDesignResult {
    designDetails?: DesignDetails;
    resetAction?: boolean;
}

export interface StrengthUpdateDesignResult extends UpdateDesignResult {
    designDetails?: StrengthDesignDetails;
}

export interface PunchUpdateDesignResult extends UpdateDesignResult {
    designDetails?: PunchDesignDetails;
}

export const enum ReportPaperSizeId {
    A4 = 1,
    Letter = 2
}

export interface ApiDesignReportGenerateOptions {
    projectDesign: ProjectDesign;
    calculateResult: CalculateResultReport;
    reportPaperSizeId: ReportPaperSizeId;
    localization: LocalizationOptions;
    template?: TemplateOptions;
    version: string;
    hiltiOnlineUrl?: string;
    designName: string;
    reportScreenshot: string;
}

export interface StrengthApiDesignReportGenerateOptions extends ApiDesignReportGenerateOptions {
    projectDesign: StrengthProjectDesign;
    calculateResult: StrengthCalculateResultReport;
}

export interface PunchApiDesignReportGenerateOptions extends ApiDesignReportGenerateOptions {
    projectDesign: PunchProjectDesign;
    calculateResult: PunchCalculateResultReport;
}

export type HtmlReportPaperSize = 'a4' | 'letter';

export interface ApiHtmlReportGenerateOptions {
    html: string;
    reportPaperSize: HtmlReportPaperSize;
}

export interface CalculateResultReport {
    isDesignValid: boolean;
    kernelResult: KernelResult;
    scopeCheckWarnings: string[];
    scopeCheckWarningsIds: number[];
}

export interface StrengthCalculateResultReport extends CalculateResultReport {
    utilizationResults: UtilizationResults[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PunchCalculateResultReport extends CalculateResultReport {
    // TODO TEAM: add punch report calculation data and remove eslint suppress
}

export interface ApiDesignCalculationOptions {
    projectDesign: ProjectDesign;
}

export interface ApiUpdateDesignProperty {
    propertyId: string;
    propertyValue: unknown;
}

export type StrengthApiUpdateDesignProperty = {
    [P in StrengthWritablePropertyId]: {
        propertyId: P;
        propertyValue: StrengthProperties[P];
    };
}[StrengthWritablePropertyId];

export type PunchApiUpdateDesignProperty = {
    [P in PunchWritablePropertyId]: {
        propertyId: P;
        propertyValue: PunchProperties[P];
    };
}[PunchWritablePropertyId];

export interface UpdateAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
    requiresConfirmation?: RequiresConfirmation;
}

export interface StrengthUpdateAndCalculateResult extends UpdateAndCalculateResult {
    projectDesign: StrengthProjectDesign;
    designDetails: StrengthDesignDetailsData;
    calculationResult: StrengthCalculationResult | undefined;
}

export interface PunchUpdateAndCalculateResult extends UpdateAndCalculateResult {
    projectDesign: PunchProjectDesign;
    designDetails: PunchDesignDetailsData;
    calculationResult: PunchCalculationResult | undefined;
}

export interface CreateAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
}

export interface StrengthCreateAndCalculateResult extends CreateAndCalculateResult {
    projectDesign: StrengthProjectDesign;
    designDetails: StrengthDesignDetailsData;
    calculationResult: StrengthCalculationResult | undefined;
}

export interface PunchCreateAndCalculateResult extends CreateAndCalculateResult {
    projectDesign: PunchProjectDesign;
    designDetails: PunchDesignDetailsData;
    calculationResult: PunchCalculationResult | undefined;
}

export interface ConvertAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
}

export interface StrengthConvertAndCalculateResult extends ConvertAndCalculateResult {
    projectDesign: StrengthProjectDesign;
    designDetails: StrengthDesignDetailsData;
    calculationResult: StrengthCalculationResult | undefined;
}

export interface PunchConvertAndCalculateResult extends ConvertAndCalculateResult {
    projectDesign: PunchProjectDesign;
    designDetails: PunchDesignDetailsData;
    calculationResult: PunchCalculationResult | undefined;
}

export interface UpdateResult {
    designCalculationResult?: UpdateAndCalculateResult;
    resetAction?: boolean;
}

export interface StrengthUpdateResult extends UpdateResult {
    designCalculationResult?: StrengthUpdateAndCalculateResult;
}

export interface PunchUpdateResult extends UpdateResult {
    designCalculationResult?: PunchUpdateAndCalculateResult;
}

export interface ZoneLoad {
    zoneNumber: number;
    load: number;
}

export const enum ConcreteMember {
    Slab = 1,
    Beam = 2,
    Column = 3,
    Wall = 4,
}
export const enum BaseMember {
    Slab = 1,
}

export const enum UtilizationValue {
    Value = 1,
    NaN = 2,
    InfinityPlus = 3,
    InfinityMinus = 4,
}

export const enum UtilizationType {
    ExistingMemberStrutResistance = 1,
    ExistingMemberConcreteResistance = 2,
    ExistingMemberShearReinforcement = 3,
    ExistingMemberStrengtheningNeeded = 4,
    StrengthenedMemberStrutResistance = 5,
    StrengthenedMemberPostInstalledReinforcement = 6,
    StrengthenedMemberNumberOfStrengtheningElements = 7,
    StrengthenedMemberDrillLength = 8,
    AdditionalTensileForceFromShear = 9,
}

export const enum RegionId {
    Germany = 1,
    UnitedKingdom = 5,
    Austria = 7,
    Sweden = 11,
    Switzerland = 12,
}

export const enum MemberVerification {
    EN199211 = 1,
    SIA262 = 2
}

export interface OpenDesignResult {
    designDetails: DesignDetails;
    trackingDetails: TrackingDetails;
    // TODO TEAM: this should be an array of ProjectDesign property names that were changed
    convertChanges: string;
}

export interface CreateDesignResult {
    designDetails: DesignDetails;
    trackingDetails: TrackingDetails;
}

export interface StrengthCreateDesignResult extends CreateDesignResult {
    designDetails: StrengthDesignDetails;
}

export interface PunchCreateDesignResult extends CreateDesignResult {
    designDetails: PunchDesignDetails;
}

type OnStateChangeFunction = (this: void, design: Design, state: IDesignStateBase, oldState: IDesignStateBase, stateChange: StateChange) => void;

interface PendingUpdateDesign {
    request: Promise<{ designDetails?: DesignDetails } | undefined>;
    properties: ApiUpdateDesignProperty[];
    cancel: () => void;
}

interface DebounceRequestPending {
    requestId: string;
    request: Promise<unknown>;
}

export function designTypeSwitch<TResult>(designTypeId: DesignTypeId, strengthFn: () => unknown, punchFn: () => unknown): TResult {
    switch (designTypeId) {
        case designTypes.strength.id:
            return strengthFn() as TResult;
        case designTypes.punch.id:
            return punchFn() as TResult;
        default:
            throw new Error('unknown DesignTypeId');
    }
}

class DebounceRequest {
    constructor (private guidService: GuidService) { }

    public pendingRequest: DebounceRequestPending = {
        requestId: '',
        request: Promise.resolve()
    };
    public nextRequestId?: string;

    public async request<T>(request: () => Promise<T>, immediateRequest: boolean): Promise<T | undefined> {
        const requestId = this.guidService.new();
        this.nextRequestId = requestId;

        if (!immediateRequest) {
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        await this.pendingRequest.request;

        // skip intermediate document service requests
        if (this.nextRequestId != requestId) {
            return undefined;
        }

        this.pendingRequest = {
            requestId,
            request: Promise.resolve().then(request)
        };

        return await (this.pendingRequest.request as Promise<T>);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DesignService {
    constructor (
        private localizationService: LocalizationService,
        private documentService: DocumentService,
        private userSettingsService: UserSettingsService,
        private designTemplateService: DesignTemplateService,
        private numberService: NumberService,
        private commonCodeListService: CommonCodeListService,
        private dataService: DataService,
        private modalService: ModalService,
        private changesService: ChangesService,
        private spApiService: SpApiService,
        private guidService: GuidService,
        private trackingService: TrackingService,
        private featuresVisibilityService: FeatureVisibilityService
    ) {
        this.debounceDocumentService = new DebounceRequest(this.guidService);
        this.debounceDocumentServiceImage = new DebounceRequest(this.guidService);
        this.debounceTracking = new DebounceRequest(this.guidService);
    }

    private debounceDocumentService: DebounceRequest;
    private debounceDocumentServiceImage: DebounceRequest;
    private debounceTracking: DebounceRequest;

    private pendingUpdateDesign?: PendingUpdateDesign;
    private pendingUpdateDesignTemplate?: PendingUpdateDesign;

    // when new property is added to 'Properties' interface, add it here
    private designPropertiesTranslationKeys = new Map<string, string>([
        ['regionId', 'SP.ApplicationSettings.Region'],
        ['designStandardId', 'SP.DesignStandard'],
        ['alphaCC', 'SP.ReductionFactors.AlphaCC'],
        ['e', 'SP.SteelMaterial.E'],
        ['gammaS', 'SP.SafetyFactors.GammaS'],
        ['gammaC', 'SP.SafetyFactors.GammaC'],
        ['shearStrutAngle', 'SP.ApplicationSettings.ShearCalculation.ShearStrutAngle'],
        ['etaT', 'SP.ReductionFactors.EtaT'],
        ['Kc', 'SP.ApplicationSettings.ShearCalculation.Kc'],
        ['epsilonV', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.EpsilonV'],
        ['aggregateSizeId', 'SP.Navigation.TabLoads.RegionDesign.AggregateSizeId'],
        ['unitLength', 'SP.AddEditDesign.Units.Length'],
        ['unitArea', 'SP.AddEditDesign.Units.Area'],
        ['unitStress', 'SP.AddEditDesign.Units.Stress'],
        ['unitForce', 'SP.AddEditDesign.Units.Force'],
        ['unitMoment', 'SP.AddEditDesign.Units.Moment'],
        ['unitTemperature', 'SP.AddEditDesign.Units.Temperature'],
        ['unitForcePerLength', 'SP.AddEditDesign.Units.ForcePerLength'],
        ['unitDensity', 'SP.AddEditDesign.Units.Density'],
        ['unitAreaPerLength', 'SP.AddEditDesign.Units.AreaPerLength'],
        ['shortTermTemperature', 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm'],
        ['longTermTemperature', 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm'],
        ['slabWidth', 'SP.Navigation.TabBaseMaterial.RegionGeometry.Width'],
        ['slabLength', 'SP.Navigation.TabBaseMaterial.RegionGeometry.Length'],
        ['slabHeight', 'SP.Navigation.TabBaseMaterial.RegionGeometry.Height'],
        ['zonesNumberId', 'SP.Navigation.TabBaseMaterial.RegionZones.NumberOfZones'],
        ['baseMaterialId', 'SP.Navigation.TabBaseMaterial'],
        ['fcCylinder', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCyl'],
        ['fcCube', 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCube'],
        ['loadTypeId', 'SP.Navigation.TabLoads.RegionLoadType.LoadType'],
        ['axialStress', 'SP.Navigation.TabLoads.RegionLoadType.AxialStress'],
        ['postInstalledReinforcementDesignId', 'SP.Navigation.TabPostInstalled'],
        ['longitudinalReinforcementYieldStrength', ''],
        // TODO: it might come back later with https://hilti.atlassian.net/browse/PSP-178
        //[ 'definedTensionBars', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineBars' ],
        ['crossSectionalArea', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea'],
        ['cover', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover'],
        ['effectiveHeight', 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight'],
        ['zone1StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z1'],
        ['zone1InstallationDirection', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z1'],
        ['zone1SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z1'],
        ['zone1SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z1'],
        ['zone1MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z1'],
        ['zone1MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z1'],
        ['zone1TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z1'],
        ['zone1Length', 'SP.Navigation.TabBaseMaterial.RegionZones.Z1Width'],
        ['zone2StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z2'],
        ['zone2InstallationDirection', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z2'],
        ['zone2SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z2'],
        ['zone2SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z2'],
        ['zone2MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z2'],
        ['zone2MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z2'],
        ['zone2TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z2'],
        ['zone2Length', 'SP.Navigation.TabBaseMaterial.RegionZones.Z2Width'],
        ['zone3StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z3'],
        ['zone3InstallationDirection', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z3'],
        ['zone3SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z3'],
        ['zone3SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z3'],
        ['zone3MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z3'],
        ['zone3MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z3'],
        ['zone3TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z3'],
        ['zone3Length', 'SP.Navigation.TabBaseMaterial.RegionZones.Z3Width'],
        ['zone4StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z4'],
        ['zone4InstallationDirection', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection.z4'],
        ['zone4SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z4'],
        ['zone4SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z4'],
        ['zone4MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z4'],
        ['zone4MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z4'],
        ['zone4TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z4'],
        ['zone4Length', 'SP.Navigation.TabBaseMaterial.RegionZones.Z4Width'],
        ['zone5StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z5'],
        ['zone5SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z5'],
        ['zone5SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z5'],
        ['zone5MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z5'],
        ['zone5MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z5'],
        ['zone5TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z5'],
        ['zone6StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z6'],
        ['zone6SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z6'],
        ['zone6SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z6'],
        ['zone6MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z6'],
        ['zone6MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z6'],
        ['zone6TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z6'],
        ['zone7StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z7'],
        ['zone7SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z7'],
        ['zone7SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z7'],
        ['zone7MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z7'],
        ['zone7MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z7'],
        ['zone7TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z7'],
        ['zone8StrengtheningElementDefinition', 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement.z8'],
        ['zone8SpacingX', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX.z8'],
        ['zone8SpacingY', 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY.z8'],
        ['zone8MinimumEdgeDistanceX', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX.z8'],
        ['zone8MinimumEdgeDistanceY', 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY.z8'],
        ['zone8TransverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity.z8'],
        ['zone1LengthLock', ''],  // no text on UI
        ['zone2LengthLock', ''],  // no text on UI
        ['zone3LengthLock', ''],  // no text on UI
        ['zone4LengthLock', ''],  // no text on UI
        ['existingMemberVerificationId', 'SP.Navigation.TabLoads.RegionDesign.ExistingMemberVerification'],
        ['nationalAnnex', 'SP.Navigation.TabLoads.RegionDesign.UseNationalAnnex'],
        ['zoneLoads', ''],
        ['loadCombinationName', ''],
        ['drillingTypeId', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingType'],
        ['holeTypeId', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.HoleType'],
        ['drillingAidId', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingAid'],
        ['depthOfRecess', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DepthOfRecess'],
        // TODO: it might come back later with https://hilti.atlassian.net/browse/PSP-236
        //[ 'withoutNutAndWasher', 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.WithoutNutAndWasher' ],
        ['concreteMemberId', 'SP.Navigation.TabApplication.RegionApplication.ConcreteMember'],
        ['loadCombinations', 'SP.Report.Loads.LoadCombinations'],
        ['fastenerId', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size'],
        ['fastenerFamilyId', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type'],
        ['fastenerFamilyGroupId', 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family'],
        ['reportStrengtheningApplication', 'SP.Report.CompanySection.StrengtheningApplication'],
        ['reportNotes', 'SP.Report.Notes'],
        ['defineOpening', 'SP.Navigation.TabBaseMaterial.RegionOpenings.DefineOpening'],
        ['openingLength', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength'],
        ['openingWidth', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth'],
        ['openingOriginX', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX'],
        ['openingOriginY', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY'],
        ['selectedLoadCombinationIndex', ''],
        ['reportTemplateId', ''],
        ['reportTypeId', ''],
        ['reportFirstPage', ''],
        ['reportLanguageId', ''],
        ['reportPaperSizeId', ''],
        ['reportCompanyName', ''],
        ['reportAddress', ''],
        ['reportContactPerson', ''],
        ['reportPhoneNumber', ''],
        ['reportEmail', ''],
        ['zones', 'SP.Report.Loads.Layout.Zone'],
        ['installationDirectionId', 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection'],
        ['transverseEccentricity', 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity'],
        ['baseMemberId', 'SP.Navigation.TabApplication.RegionApplication.BaseMember'],
        ['compressionMemberId', 'SP.Navigation.TabApplication.RegionApplication.CompressionMember'],
        ['punchLength', 'SP.Navigation.TabApplication.RegionColumnDimensions.Length'],
        ['punchWidth', 'SP.Navigation.TabApplication.RegionGeometry.Width'],
        ['openingsNumberId', 'SP.Navigation.TabBaseMaterial.RegionOpenings.NumberOfOpenings'],
        ['punchOpening1Length', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o1'],
        ['punchOpening2Length', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o2'],
        ['punchOpening3Length', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength.o3'],
        ['punchOpening1Width', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o1'],
        ['punchOpening2Width', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o2'],
        ['punchOpening3Width', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth.o3'],
        ['punchOpening1OriginX', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o1'],
        ['punchOpening2OriginX', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o2'],
        ['punchOpening3OriginX', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX.o3'],
        ['punchOpening1OriginY', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o1'],
        ['punchOpening2OriginY', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o2'],
        ['punchOpening3OriginY', 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY.o3'],
    ]);

    /** Default design name in add edit design popup for strength */
    public getNewStrengthDesignName() {
        const designName = this.localizationService.getString('SP.NewDesign.Strength.DefaultName');

        return `${designName} - ${this.localizationService.moment(new Date()).format('ll')}`;
    }

    /** Default design name in add edit design popup for punch */
    public getNewPunchDesignName() {
        const designName = this.localizationService.getString('SP.NewDesign.Punch.DefaultName');

        return `${designName} - ${this.localizationService.moment(new Date()).format('ll')}`;
    }

    /**
     * Will do the following:
     * 1. new project design - design-service/design/create
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async createDesign(options: CreateDesignOptions): Promise<CreateDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceCreateAndCalculate(options);

        const documentDesign = await this.documentServiceCreateDesign({
            designName: options.designName,
            designStandardId: options.designStandardId,
            designTypeId: options.designTypeId,
            projectDesign: projectDesign,
            projectId: options.projectId,
            regionId: options.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.getProjectName(documentDesign.projectId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'Blank');

        return {
            designDetails,
            trackingDetails
        };
    }

    public strengthCreateDesign(options: StrengthCreateDesignOptions): Promise<StrengthCreateDesignResult> {
        return this.createDesign(options) as Promise<StrengthCreateDesignResult>;
    }

    public punchCreateDesign(options: PunchCreateDesignOptions): Promise<PunchCreateDesignResult> {
        return this.createDesign(options) as Promise<PunchCreateDesignResult>;
    }

    /**
     * Will do the following:
     * 1. get design details from project design - design-service/design/details
     * 2. call calculation - calculation-service/calculation/calculate
     * 3. save-and-open document service - document-service/document
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async createDesignFromProjectDesign(options: CreateDesignFromProjectDesignOptions, apiOptions?: ApiOptions): Promise<OpenDesignResult> {
        const {
            designDetails: designDetailsData,
            projectDesign,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(options.projectDesign, apiOptions);

        const convertChanges = this.checkForConversionChanges(options.projectDesign, projectDesign);

        const documentDesign = await this.documentServiceCreateDesign({
            designName: options.designName,
            designStandardId: designDetailsData.properties.designStandardId,
            designTypeId: designDetailsData.designTypeId,
            projectId: options.projectId,
            regionId: designDetailsData.regionId,
            projectDesign
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.getProjectName(documentDesign.projectId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'ImportedProfis3File');

        return {
            designDetails,
            trackingDetails,
            convertChanges
        };
    }

    /**
     * Returns undefined when we have other pending updates.
     *
     * Will do the following:
     * 1. update project design - design-service/design/convert
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Undefined values in properties are not updated. Null values are updated.
     */
    public async updateDesign(options: UpdateDesignOptions): Promise<UpdateDesignResult | undefined> {
        this.logChangeRequest(options.properties);

        let properties: PropertyIdValue[] = [
            ...options.properties
        ];

        // cancel existing update
        if (this.pendingUpdateDesign != null) {
            properties = [
                ...this.pendingUpdateDesign.properties,
                ...properties,
            ];

            this.pendingUpdateDesign.cancel();
        }

        const cancellationTokenSource = new CancellationTokenSource();
        const apiOptions: ApiOptions = {
            cancellationToken: cancellationTokenSource.token
        };

        const pendingUpdateDesign = this.pendingUpdateDesign = {
            properties,
            cancel: () => cancellationTokenSource.cancel(),
            request: Promise.resolve().then(async (): Promise<UpdateDesignResult | undefined> => {
                try {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    const updateResult = await this.coreServiceUpdateAndCalculate({
                        projectDesign: options.projectDesign,
                        properties
                    }, apiOptions);

                    if (updateResult.resetAction) {
                        return {
                            resetAction: updateResult.resetAction
                        };
                    }

                    const projectDesign = updateResult.designCalculationResult!.projectDesign;
                    const designDetailsData = updateResult.designCalculationResult!.designDetails;
                    const calculationResult = updateResult.designCalculationResult!.calculationResult;

                    const designDetails = this.createDesignDetails({
                        projectDesign,
                        designDetailsData,
                        calculationResult,

                        designId: options.designId,
                        designName: options.designName,
                        projectId: options.projectId,
                        projectName: this.getProjectName(options.projectId)
                    });

                    const documentServiceUpdateDesignPromise = this.documentServiceUpdateDesign({
                        designId: options.designId,
                        designName: options.designName,
                        designStandardId: designDetails.properties.designStandardId,
                        designTypeId: designDetails.designTypeId,
                        projectDesign: projectDesign,
                        projectId: options.projectId,
                        regionId: designDetails.regionId,

                        immediateRequest: options.immediateRequest
                    });

                    const trackingPromise = this.trackOnDesignOrTemplateChange({
                        designDetails,
                        trackingDetails: options.trackingDetails,

                        immediateRequest: options.immediateRequest
                    });

                    // true: call document service immediately and return a Promise that awaits the document service request
                    // false: call document service in the background with debounce and return a Promise that does NOT await the document service request
                    if (options.immediateRequest) {
                        await trackingPromise;

                        const documentServiceUpdateDesign = await documentServiceUpdateDesignPromise;
                        if (documentServiceUpdateDesign == null) {
                            return undefined;
                        }
                    }

                    return {
                        designDetails
                    };
                }
                catch (error) {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    throw error;
                }
                finally {
                    if (this.pendingUpdateDesign === pendingUpdateDesign) {
                        this.pendingUpdateDesign = undefined;
                    }
                }
            })
        };

        return await this.pendingUpdateDesign.request;
    }

    public strengthUpdateDesign(options: StrengthUpdateDesignOptions): Promise<StrengthUpdateDesignResult | undefined> {
        return this.updateDesign(options) as Promise<StrengthUpdateDesignResult | undefined>;
    }

    public async punchUpdateDesign(options: PunchUpdateDesignOptions): Promise<PunchUpdateDesignResult | undefined> {
        return this.updateDesign(options) as Promise<PunchUpdateDesignResult | undefined>;
    }

    public async updateDesignOrDesignTemplate(options: UpdateDesignOrDesignTemplateOptions): Promise<UpdateDesignResult | undefined> {
        if (options.designId != null && options.designName != null && options.projectId != null) {
            return await this.updateDesign({
                designId: options.designId,
                designName: options.designName,
                projectId: options.projectId,

                projectDesign: options.projectDesign,
                properties: options.properties,

                trackingDetails: options.trackingDetails,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null && options.templateName != null) {
            return await this.updateDesignTemplate({
                templateId: options.templateId,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId,

                projectDesign: options.projectDesign,
                properties: options.properties,

                trackingDetails: options.trackingDetails,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId/designName/projectId or templateId/templateName/templateProjectId');
        }
    }

    public strengthUpdateDesignOrDesignTemplate(options: StrengthUpdateDesignOrDesignTemplateOptions): Promise<StrengthUpdateDesignResult | undefined> {
        return this.updateDesignOrDesignTemplate(options) as Promise<StrengthUpdateDesignResult | undefined>;
    }

    public punchUpdateDesignOrDesignTemplate(options: PunchUpdateDesignOrDesignTemplateOptions): Promise<PunchUpdateDesignResult | undefined> {
        return this.updateDesignOrDesignTemplate(options) as Promise<PunchUpdateDesignResult | undefined>;
    }

    /**
     * Will do the following:
     * 1. convert to latest project design - design-service/design/convert
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     */
    public async updateDesignFromProjectDesign(options: UpdateDesignFromProjectDesignOptions, apiOptions?: ApiOptions): Promise<OpenDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(options.projectDesign, apiOptions);

        const convertChanges = this.checkForConversionChanges(options.projectDesign, projectDesign);

        const documentDesign = (await this.documentServiceUpdateDesign({
            designId: options.designId,
            designName: options.designName,
            designStandardId: designDetailsData.properties.designStandardId,
            designTypeId: designDetailsData.designTypeId,
            projectDesign: projectDesign,
            projectId: options.projectId,
            regionId: designDetailsData.regionId,

            immediateRequest: options.immediateRequest
        }))!;

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.getProjectName(documentDesign.projectId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'ImportedProfis3File');

        return {
            designDetails,
            trackingDetails,
            convertChanges
        };
    }

    /**
     * Will do the following:
     * 1. (async - check options.immediateRequest) update document image - document-service/documentcontent/UploadDocumentImage
     */
    public async updateDesignImage(options: UpdateDesignImageOptions) {
        return await this.debounceDocumentServiceImage.request(async () => {
            await this.documentService.updateDesignThumbnailImage(options.designId, options.base64Image, false);
        },
            options.immediateRequest);
    }

    public async updateDesignImageOrTemplateDesignImage(options: UpdateDesignImageOrTemplateDesignImageOptions) {
        if (options.designId != null) {
            await this.updateDesignImage({
                designId: options.designId,
                base64Image: options.base64Image,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null) {
            await this.updateDesignTemplateImage({
                templateId: options.templateId,
                base64Image: options.base64Image,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId or templateId');
        }
    }

    /**
     * Will do the following:
     * 1. (async - check options.immediateRequest) update document image - document-service/DocumentDesignTemplate/ThumbnailUpdate
     */
    public async updateDesignTemplateImage(options: UpdateDesignTemplateImageOptions) {
        return await this.debounceDocumentServiceImage.request(async () => {
            await this.designTemplateService.updateDesignThumbnailImage(options.templateId, options.base64Image, false);
        },
            options.immediateRequest);
    }

    /**
     * Will do the following:
     * 1. get document service - document-service/documentContent/GetExclusive
     * 2. convert to latest project design - design-service/design/convert
     * 3. get design details from project design - design-service/design/details
     * 4. call calculation - calculation-service/calculation/calculate
     * 5. update document service - document-service/documentcontent
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async openDesign(options: OpenDesignOptions): Promise<OpenDesignResult> {
        try {
            const documentDesign = this.documentService.findDesignById(options.designId);
            const projectDesign = await this.documentService.openDesignExclusive<ProjectDesign>(documentDesign);

            const {
                projectDesign: updatedProjectDesign,
                designDetails: designDetailsData,
                calculationResult
            } = await this.coreServiceConvertAndCalculate(projectDesign);

            const convertChanges = this.checkForConversionChanges(projectDesign, updatedProjectDesign);

            // TODO FILIP: do we only update if we have changes from designServiceUpdateDesign?
            const updatedDocumentDesign = (await this.documentServiceUpdateDesign({
                designId: options.designId,
                designName: documentDesign.designName,
                designStandardId: designDetailsData.properties.designStandardId,
                designTypeId: designDetailsData.designTypeId,
                projectDesign: updatedProjectDesign,
                projectId: documentDesign.projectId,
                regionId: designDetailsData.regionId,

                immediateRequest: true
            }))!;

            const designDetails = this.createDesignDetails({
                projectDesign: updatedProjectDesign,
                designDetailsData,
                calculationResult,

                designId: updatedDocumentDesign.id,
                designName: updatedDocumentDesign.designName,
                projectId: documentDesign.projectId,
                projectName: this.getProjectName(documentDesign.projectId)
            });

            // tracking open
            const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'OpenExisting');

            return {
                designDetails,
                trackingDetails,
                convertChanges
            };
        }
        catch (error) {
            if (error instanceof CantOpenDesignBecauseLockedByOtherUser) {
                this.modalService.openAlertWarning(this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'), {
                        user: error.username ?? ''
                    })
                );
            }

            throw error;
        }
    }

    /**
     * Will do the following:
     * 1. close document on document service
     * 2. track design close
     */
    public async closeDesign(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        // document close
        await this.documentService.publish(designDetails.designId!);

        // tracking close
        await this.trackingService.trackOnDesignClose(designDetails, trackingDetails);
    }

    public createPeDesignObject(designDetails: DesignDetails, trackingDetails: TrackingDetails, convertChanges?: string): Design {
        const metaData = {};
        const designType = {};
        const region = {};
        const onStateChangedFunctions: OnStateChangeFunction[] = [];

        const peDesignObject = {
            metaData,
            designType,
            region,
            onStateChangedFunctions,
            designStandard: {
                // TODO FILIP: why does pe-ui need this?
                id: 0
            },
            properties: {
                get: (propertyId: PropertyId): IProperty => {
                    // pe-ui is calling this function even when we don't set UIProperty
                    if (propertyId == null) {
                        return {
                            disabled: false,
                            hidden: false,
                            itemsTexts: {}
                        };
                    }

                    const designDetails = peDesignObject.designDetails;
                    const propertyDetails = designDetails.propertyDetails[propertyId];

                    // missing property details
                    if (propertyDetails == null) {
                        console.warn(`Missing designDetails.propertyDetails for '${propertyId}'`);

                        return {
                            disabled: false,
                            hidden: false,
                            itemsTexts: {}
                        };
                    }

                    return {
                        disabled: propertyDetails.disabled === true,
                        hidden: propertyDetails.hidden === true,
                        allowedValues: propertyDetails.allowedValues,
                        min: propertyDetails.minValue,
                        max: propertyDetails.maxValue,
                        itemsTexts: {}
                    };
                }
            },
            onStateChanged: (fn: OnStateChangeFunction) => {
                onStateChangedFunctions.push(fn);
            },
            onAllowedValuesChanged: () => {
                // not needed
            },
            on: () => {
                // not needed
            },
            dispose: () => {
                onStateChangedFunctions.splice(0, onStateChangedFunctions.length);
            },
            // needed for button prop - menu.service in pe-ui. otherwise, pe-ui will throw an error
            pendingCalculationChange: new Subject<void>().asObservable(),
            trackingDetails
        } as unknown as InternalDesign;

        // define as getters so we don't need to update them
        Object.defineProperties(peDesignObject, {
            id: {
                get: () => peDesignObject.designDetails.designId
            },
            projectId: {
                get: () => peDesignObject.designDetails.projectId
            },
            designName: {
                get: () => peDesignObject.designDetails.designName
            },
            templateId: {
                get: () => peDesignObject.designDetails.templateId
            },
            isTemplate: {
                get: () => peDesignObject.designDetails.isTemplate
            },
            templateName: {
                get: () => peDesignObject.designDetails.templateName,
                set: () => {
                    // TODO TEAM: fix common - pe-ui is trying to override templateName on design for some strange reason
                }
            },
            projectDesign: {
                get: () => peDesignObject.designDetails.projectDesign
            },
            designTypeId: {
                get: () => peDesignObject.designDetails.designTypeId
            },
            regionId: {
                get: () => peDesignObject.designDetails.regionId
            },

            // needed by unit.service - return UnitType.None if we don't support a unit for a specific design type
            unitLength: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitLength ?? UnitType.None
            },
            unitLengthLarge: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitLength ?? UnitType.None
            },
            unitArea: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitArea ?? UnitType.None
            },
            unitStress: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitStress ?? UnitType.None
            },
            unitStressSmall: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitStress ?? UnitType.None
            },
            unitForce: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitForce ?? UnitType.None
            },
            unitMoment: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitMoment ?? UnitType.None
            },
            unitTemperature: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitTemperature ?? UnitType.None
            },
            unitForcePerLength: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitForcePerLength ?? UnitType.None
            },
            unitMomentPerLength: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitMoment ?? UnitType.None
            },
            unitDensity: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitDensity ?? UnitType.None
            },
            unitAreaPerLength: {
                get: () => (peDesignObject.designDetails.properties as UnitProperties).unitAreaPerLength ?? UnitType.None
            },
        });

        Object.defineProperties(metaData, {
            region: {
                get: () => peDesignObject.designDetails.regionId
            },
            designType: {
                get: () => peDesignObject.designDetails.designTypeId
            },
            standard: {
                get: () => peDesignObject.designDetails.properties.designStandardId
            },
        });

        Object.defineProperties(designType, {
            id: {
                get: () => peDesignObject.designDetails.designTypeId
            },
        });

        Object.defineProperties(region, {
            id: {
                get: () => peDesignObject.designDetails.regionId
            },
        });

        this.updatePeDesignObject(peDesignObject, designDetails, convertChanges);
        return peDesignObject;
    }

    public updatePeDesignObject(peDesignObject: Design, designDetails: DesignDetails, convertChanges?: string) {
        const internalPeDesignObject = peDesignObject as InternalDesign;
        internalPeDesignObject.designDetails = designDetails;
        internalPeDesignObject.convertChanges = convertChanges;

        // set change detection
        const model = peDesignObject.model = {} as Record<string, unknown>;
        for (const propertyId in designDetails.properties) {
            model[propertyId as unknown as number] = designDetails.properties[propertyId as PropertyId];
        }

        const modelChanges = peDesignObject.modelChanges ??= new TrackChanges({
            collapse: true,
            ignoreUndefined: true,
            shallowChanges: true,
            // TODO FILIP: fix TrackChanges input interface
            changesService: this.changesService,
            logger: {} as unknown as LoggerServiceBase
        });

        modelChanges.set(model);
        modelChanges.observe();
        this.logChangeResponse(modelChanges.changes);
        modelChanges.clear();

        // trigger state changed event for menu
        const onStateChangedFunctions = (peDesignObject as unknown as { onStateChangedFunctions: OnStateChangeFunction[] }).onStateChangedFunctions;
        for (const onStateChangedFunction of onStateChangedFunctions) {
            onStateChangedFunction(peDesignObject, {
                model,
                properties: peDesignObject.properties
            } as IDesignStateBase, undefined as unknown as IDesignStateBase, undefined as unknown as StateChange);
        }
    }

    /**
     * Will do the following:
     * 1. new project design - design-service/design/create
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesignTemplate MUST BE CALLED WHEN DONE WORKING WITH DESIGN TEMPLATE
     */
    public async createDesignTemplate(options: CreateDesignTemplateOptions): Promise<CreateDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceCreateAndCalculate(options);

        const documentDesign = await this.documentServiceCreateDesignTemplate({
            templateName: options.templateName,
            templateProjectId: options.templateProjectId,
            designStandardId: options.designStandardId,
            designTypeId: options.designTypeId,
            projectDesign: projectDesign,
            regionId: options.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            templateId: documentDesign.DesignTemplateDocumentId,
            templateName: documentDesign.DesignTemplateName,
            templateProjectId: documentDesign.templateFolderId,
            templateProjectName: this.getTemplateProjectName(documentDesign.templateFolderId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnTemplateOpen(designDetails, 'TemplateEdit');

        return {
            designDetails,
            trackingDetails
        };
    }

    public strengthCreateDesignTemplate(options: StrengthCreateDesignTemplateOptions): Promise<StrengthCreateDesignResult> {
        return this.createDesignTemplate(options) as Promise<StrengthCreateDesignResult>;
    }

    public punchCreateDesignTemplate(options: PunchCreateDesignTemplateOptions): Promise<PunchCreateDesignResult> {
        return this.createDesignTemplate(options) as Promise<PunchCreateDesignResult>;
    }

    public async createDesignOrDesignTemplate(options: CreateDesignOrDesignTemplateOptions): Promise<CreateDesignResult> {
        if (options.designName != null && options.projectId != null) {
            return await this.createDesign({
                ...options,
                designName: options.designName,
                projectId: options.projectId
            });
        }
        else if (options.templateName != null) {
            return await this.createDesignTemplate({
                ...options,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId
            });
        }
        else {
            throw new Error('Must set designName/projectId or templateName/templateProjectId');
        }
    }

    public async strengthCreateDesignOrDesignTemplate(options: StrengthCreateDesignOrDesignTemplateOptions): Promise<StrengthCreateDesignResult> {
        return this.createDesignOrDesignTemplate(options) as Promise<StrengthCreateDesignResult>;
    }

    public async punchCreateDesignOrDesignTemplate(options: PunchCreateDesignOrDesignTemplateOptions): Promise<PunchCreateDesignResult> {
        return this.createDesignOrDesignTemplate(options) as Promise<PunchCreateDesignResult>;
    }

    /**
     * Will do the following:
     * 1. get template document service - document-service/DocumentDesignTemplate/Get
     * 2. convert to latest project design - design-service/design/convert
     * 3. get design details from project design - design-service/design/details
     * 4. call calculation - calculation-service/calculation/calculate
     * 5. update template document service - document-service/DocumentDesignTemplate/Update
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * closeDesignTemplate MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async openDesignTemplate(options: OpenDesignTemplateOptions): Promise<OpenDesignResult> {
        const documentDesignTemplate = await this.designTemplateService.getById(options.designTemplateId);
        const projectDesign: ProjectDesign = JSON.parse(documentDesignTemplate.ProjectDesign);

        const {
            projectDesign: updatedProjectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(projectDesign);

        const convertChanges = this.checkForConversionChanges(projectDesign, updatedProjectDesign);

        // TODO FILIP: do we only update if we have changes from designServiceUpdateDesign?
        const updatedDocumentDesignTemplate = (await this.documentServiceUpdateDesignTemplate({
            designTypeId: designDetailsData.designTypeId,
            projectDesign: updatedProjectDesign,
            regionId: designDetailsData.regionId,
            templateId: options.designTemplateId,
            templateProjectId: documentDesignTemplate.templateFolderId!,
            templateName: documentDesignTemplate.DesignTemplateName,
            designStandardId: designDetailsData.properties.designStandardId,

            immediateRequest: true
        }))!;

        const designDetails = this.createDesignDetails({
            projectDesign: updatedProjectDesign,
            designDetailsData,
            calculationResult,

            templateId: updatedDocumentDesignTemplate.DesignTemplateDocumentId,
            templateName: updatedDocumentDesignTemplate.DesignTemplateName,
            templateProjectId: updatedDocumentDesignTemplate.templateFolderId,
            templateProjectName: this.getTemplateProjectName(updatedDocumentDesignTemplate.templateFolderId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnTemplateOpen(designDetails, 'TemplateEdit');

        return {
            designDetails,
            trackingDetails,
            convertChanges
        };
    }

    /**
     * Will do the following:
     * 1. track design close
     */
    public async closeDesignTemplate(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        // tracking close
        await this.trackingService.trackOnTemplateClose(designDetails, trackingDetails);
    }

    public async closeDesignOrDesignTemplate(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        if (designDetails.isTemplate) {
            await this.closeDesignTemplate(designDetails, trackingDetails);
        }
        else {
            await this.closeDesign(designDetails, trackingDetails);
        }
    }

    /**
     * Will do the following:
     * 1. convert to latest project design - design-service/design/convert
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesignTemplate MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async createDesignFromTemplate(options: CreateDesignFromTemplateOptions): Promise<OpenDesignResult> {
        const template = await this.designTemplateService.getById(options.templateId);
        const projectDesign: ProjectDesign = JSON.parse(template.ProjectDesign);

        const {
            projectDesign: updatedProjectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(projectDesign);

        const convertChanges = this.checkForConversionChanges(projectDesign, updatedProjectDesign);

        const documentDesign = await this.documentServiceCreateDesign({
            designName: this.getTemplateDesignName(projectDesign.designTypeId, options.designName),
            designStandardId: designDetailsData.properties.designStandardId,
            designTypeId: designDetailsData.designTypeId,
            projectDesign: updatedProjectDesign,
            projectId: options.projectId ?? this.documentService.draftsProject.id!,
            regionId: designDetailsData.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign: updatedProjectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.getProjectName(documentDesign.projectId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'BlankFromTemplate');

        return {
            designDetails,
            trackingDetails,
            convertChanges
        };
    }

    private getTemplateDesignName(designTypeId: DesignTypeId, designName: string | undefined) {
        if (designName != undefined)
            return designName;
        const templateDesignName: string = designTypeSwitch(designTypeId,
            () => this.getNewStrengthDesignName(),
            () => this.getNewPunchDesignName()
        );
        return this.documentService.createUniqueName(templateDesignName, Object.values(this.documentService.draftsProject?.designs ?? []).map((item) => item.designName));
    }

    /**
     * Returns undefined when we have other pending updates.
     *
     * Will do the following:
     * 1. update project design - design-service/design/convert
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Undefined values in properties are not updated. Null values are updated.
     */
    public async updateDesignTemplate(options: UpdateDesignTemplateOptions): Promise<UpdateDesignResult | undefined> {
        this.logChangeRequest(options.properties);

        let properties = [
            ...options.properties
        ];

        // cancel existing update
        if (this.pendingUpdateDesignTemplate != null) {
            properties = [
                ...this.pendingUpdateDesignTemplate.properties,
                ...properties,
            ];

            this.pendingUpdateDesignTemplate.cancel();
        }

        const cancellationTokenSource = new CancellationTokenSource();
        const apiOptions: ApiOptions = {
            cancellationToken: cancellationTokenSource.token
        };

        const pendingUpdateDesignTemplate = this.pendingUpdateDesignTemplate = {
            properties,
            cancel: () => cancellationTokenSource.cancel(),
            request: Promise.resolve().then(async (): Promise<UpdateDesignResult | undefined> => {
                try {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    const updateResult = await this.coreServiceUpdateAndCalculate({
                        projectDesign: options.projectDesign,
                        properties
                    }, apiOptions);

                    if (updateResult.resetAction) {
                        return {
                            resetAction: updateResult.resetAction
                        };
                    }

                    const projectDesign = updateResult.designCalculationResult!.projectDesign;
                    const designDetailsData = updateResult.designCalculationResult!.designDetails;
                    const calculationResult = updateResult.designCalculationResult!.calculationResult;

                    const designDetails = this.createDesignDetails({
                        projectDesign,
                        designDetailsData,
                        calculationResult,

                        templateId: options.templateId,
                        templateName: options.templateName,
                        templateProjectId: options.templateProjectId,
                        templateProjectName: this.getTemplateProjectName(options.templateProjectId)
                    });

                    const documentServiceUpdateDesignTemplatePromise = this.documentServiceUpdateDesignTemplate({
                        templateId: options.templateId,
                        templateName: options.templateName,
                        templateProjectId: options.templateProjectId,
                        designStandardId: designDetails.properties.designStandardId,
                        designTypeId: designDetails.designTypeId,
                        projectDesign: projectDesign,
                        regionId: designDetails.regionId,

                        immediateRequest: options.immediateRequest
                    });

                    const trackingPromise = this.trackOnDesignOrTemplateChange({
                        designDetails,
                        trackingDetails: options.trackingDetails,

                        immediateRequest: options.immediateRequest
                    });

                    // true: call document service immediately and return a Promise that awaits the document service request
                    // false: call document service in the background with debounce and return a Promise that does NOT await the document service request
                    if (options.immediateRequest) {
                        await trackingPromise;

                        const documentServiceUpdateDesignTemplate = await documentServiceUpdateDesignTemplatePromise;
                        if (documentServiceUpdateDesignTemplate == null) {
                            return undefined;
                        }
                    }

                    return {
                        designDetails
                    };
                }
                catch (error) {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    throw error;
                }
                finally {
                    if (this.pendingUpdateDesignTemplate === pendingUpdateDesignTemplate) {
                        this.pendingUpdateDesignTemplate = undefined;
                    }
                }
            })
        };

        return await this.pendingUpdateDesignTemplate.request;
    }

    /** design list box for templates */
    public toDisplayDesignTemplate(template: DesignTemplateEntity, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const commonRegion = this.getCommonRegionById(template.RegionId)!;

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localizationService.moment(template.DateCreate).format('ll'),
            projectName: this.localizationService.getString(designTypesById[template.DesignTypeId as DesignTypeId].nameKey),
            rawProject: undefined,
            projectId: undefined as unknown as string,
            region: commonRegion,
            productName: '',
            approvalNumber: '',
            designType: template.DesignTypeId,
            regionDesignStandardApprovalNumber: this.createRegionDesignStandardApprovalNumber(template.RegionId, template.DesignStandardId),
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(template.DesignTemplateDocumentId) : undefined,
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(commonRegion),
            designStandardTextApprovalNumberText: this.designStandardTextApprovalNumberText(template.DesignStandardId)
        };
    }

    /** design list box */
    public toDisplayDesign(design: IDesignListItem, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const commonRegion = this.getCommonRegionById(design.metaData?.region)!;

        const designType = design.metaData?.designType;
        const designStandard = design.metaData?.standard;
        const parentProject = this.documentService.findProjectById(design.projectId);
        // TODO TEAM: fix common hardcoded date format to 'LLL'
        const localCreatedDateTime = this.localizationService.moment(design.createDate).format('MMM D, YYYY h:mm A');
        const isNewHomePage = this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => isNewHomePage ? localCreatedDateTime : this.localizationService.moment(design.createDate).format('LL'),
            rawProject: parentProject,
            projectId: design.projectId,
            productName: '',
            designType,
            approvalNumber: '',
            region: commonRegion,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail?.(design.id),
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(commonRegion),
            regionDesignStandardApprovalNumber: this.createRegionDesignStandardApprovalNumber(commonRegion.id, designStandard),
            designStandardTextApprovalNumberText: this.designStandardTextApprovalNumberText(designStandard),
            isFavorite: design.isFavorite ?? false,
            isSharedByMe: design.isSharedByMe ?? false
        };
    }

    /** Create description for quick start buttons and design list box */
    public createRegionDesignStandardApprovalNumber(regionId: number, designStandardId: number) {
        const region = this.dataService.regionsById[regionId];
        const regionName = region?.nameKey != null ? this.localizationService.getString(region.nameKey) : 'Unknown';

        const designStandard = this.dataService.designStandardsById[designStandardId];
        const designStandardName = designStandard?.nameKey != null ? this.localizationService.getString(designStandard.nameKey) : 'Unknown';

        return regionName + ', ' + designStandardName;
    }

    /** Create description for design list list view */
    public designStandardTextApprovalNumberText(designStandardId: number) {
        const designStandard = this.dataService.designStandardsById[designStandardId];
        const designStandardName = designStandard?.nameKey != null ? this.localizationService.getString(designStandard.nameKey) : 'Unknown';

        return designStandardName;
    }

    private getCommonRegionById(regionId: number | null) {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private createRegionText(region?: CommonRegion) {
        if (region?.id == SpecialRegion.Default) {
            region = this.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
        }

        // TODO FILIP: fix getCodeListTextDeps input interface
        const codeListDeps = getCodeListTextDeps(this.localizationService as unknown as LocalizationServiceBase, this.numberService);
        const text = region?.getTranslatedNameText(codeListDeps) ?? '';

        return text == '' ? this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown') : text;
    }

    private async strengthCoreServiceCreateAndCalculate(options: StrengthDesignServiceCreateDesignOptions, apiOptions?: ApiOptions): Promise<StrengthCreateAndCalculateResult> {
        const designCreateRequest: StrengthApiDesignCreateRequest = {
            designTypeId: designTypes.strength.id,
            designStandardId: options.designStandardId,
            etaT: options.etaT,
            alphaCC: options.alphaCC,
            e: options.e,
            gammaC: options.gammaC,
            gammaS: options.gammaS,
            regionId: options.regionId,
            Kc: options.Kc,
            unitArea: options.unitArea,
            unitAreaPerLength: options.unitAreaPerLength,
            unitDensity: options.unitDensity,
            unitForce: options.unitForce,
            unitForcePerLength: options.unitForcePerLength,
            unitLength: options.unitLength,
            unitMoment: options.unitMoment,
            unitStress: options.unitStress,
            unitTemperature: options.unitTemperature,
            reportLanguageId: options.reportLanguageId
        };

        return await this.spApiService.strengthApi.core.createAndCalculate(designCreateRequest, apiOptions);
    }

    private async punchCoreServiceCreateAndCalculate(options: PunchDesignServiceCreateDesignOptions, apiOptions?: ApiOptions): Promise<PunchCreateAndCalculateResult> {
        const designCreateRequest: PunchApiDesignCreateRequest = {
            designTypeId: designTypes.punch.id,
            designStandardId: options.designStandardId,
            reportLanguageId: options.reportLanguageId,
            etaT: options.etaT,
            alphaCC: options.alphaCC,
            e: options.e,
            gammaC: options.gammaC,
            gammaS: options.gammaS,
            regionId: options.regionId,
            Kc: options.Kc,
            unitArea: options.unitArea,
            unitAreaPerLength: options.unitAreaPerLength,
            unitDensity: options.unitDensity,
            unitForce: options.unitForce,
            unitForcePerLength: options.unitForcePerLength,
            unitLength: options.unitLength,
            unitStress: options.unitStress,
            unitMoment: options.unitMoment,
            unitTemperature: options.unitTemperature
        };

        return await this.spApiService.punchApi.core.createAndCalculate(designCreateRequest, apiOptions);
    }

    private coreServiceCreateAndCalculate(options: DesignServiceCreateDesignOptions, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> {
        return designTypeSwitch<Promise<CreateAndCalculateResult>>(options.designTypeId,
            () => this.strengthCoreServiceCreateAndCalculate(options as StrengthDesignServiceCreateDesignOptions, apiOptions),
            () => this.punchCoreServiceCreateAndCalculate(options as PunchDesignServiceCreateDesignOptions, apiOptions),
        );
    }

    private async strengthCoreServiceUpdateAndCalculate(options: StrengthDesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<StrengthUpdateResult> {
        const designUpdateRequest: StrengthDesignUpdateRequest = {
            projectDesign: options.projectDesign,
            properties: options.properties
        };

        return await this.spApiService.strengthApi.core.updateAndCalculate(designUpdateRequest, apiOptions);
    }

    private async punchCoreServiceUpdateAndCalculate(options: PunchDesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<PunchUpdateResult> {
        const designUpdateRequest: PunchDesignUpdateRequest = {
            projectDesign: options.projectDesign,
            properties: options.properties
        };

        return await this.spApiService.punchApi.core.updateAndCalculate(designUpdateRequest, apiOptions);
    }

    private coreServiceUpdateAndCalculate(options: DesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<UpdateResult> {
        return designTypeSwitch<Promise<UpdateResult>>(options.projectDesign.designTypeId,
            () => this.strengthCoreServiceUpdateAndCalculate(options as StrengthDesignServiceUpdateDesignOptions, apiOptions),
            () => this.punchCoreServiceUpdateAndCalculate(options as PunchDesignServiceUpdateDesignOptions, apiOptions),
        );
    }

    private strengthCoreServiceConvertAndCalculate(projectDesign: StrengthProjectDesign, apiOptions?: ApiOptions): Promise<StrengthConvertAndCalculateResult> {
        return this.spApiService.strengthApi.core.convertAndCalculate(projectDesign, apiOptions);
    }

    private punchCoreServiceConvertAndCalculate(projectDesign: PunchProjectDesign, apiOptions?: ApiOptions): Promise<PunchConvertAndCalculateResult> {
        return this.spApiService.punchApi.core.convertAndCalculate(projectDesign, apiOptions);
    }

    private coreServiceConvertAndCalculate(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ConvertAndCalculateResult> {
        return designTypeSwitch<Promise<ConvertAndCalculateResult>>(projectDesign.designTypeId,
            () => this.strengthCoreServiceConvertAndCalculate(projectDesign as StrengthProjectDesign, apiOptions),
            () => this.punchCoreServiceConvertAndCalculate(projectDesign as PunchProjectDesign, apiOptions)
        );
    }

    public async documentServiceCreateDesign(options: DocumentServiceCreateDesignOptions): Promise<IDesignListItem> {
        const design = {
            designName: options.designName,
            metaData: {
                region: options.regionId,
                designType: options.designTypeId,
                standard: options.designStandardId
            },
            projectDesign: options.projectDesign
        } as Design;

        return await this.documentService.addDesignCommon(options.projectId, design, true, true);
    }

    private createDesignDetails(options: CreateDesignDetailsOptions): DesignDetails {
        // merge propertyDetails with appPropertyDetails
        const propertyDetails = { ...options.designDetailsData.propertyDetails };
        for (const _propertyId in propertyDetails) {
            const propertyId = _propertyId as PropertyId;
            const propertyDetail = propertyDetails[propertyId] = {
                ...propertyDetails[propertyId]
            };

            const appPropertyDetail = this.dataService.getPropertyDetail(propertyId as ApiAppPropertyId,
                {
                    designTypeId: options.designDetailsData.designTypeId,
                    regionId: options.designDetailsData.regionId,
                    designStandardId: options.designDetailsData.properties.designStandardId,
                    concreteMemberId: (options.designDetailsData.properties as StrengthProperties).concreteMemberId,
                }
            );
            if (appPropertyDetail != null) {
                propertyDetail.allowedValues ??= appPropertyDetail.allowedValues;
                propertyDetail.defaultValue ??= appPropertyDetail.defaultValue;
                propertyDetail.disabled ??= appPropertyDetail.disabled;
                propertyDetail.hidden ??= appPropertyDetail.hidden;
                propertyDetail.maxValue ??= appPropertyDetail.maxValue;
                propertyDetail.minValue ??= appPropertyDetail.minValue;
                propertyDetail.precision ??= appPropertyDetail.precision;
                propertyDetail.extraPrecision ??= appPropertyDetail.extraPrecision;
            }
        }

        const designDetails: DesignDetails = {
            properties: options.designDetailsData.properties,
            propertyDetails: propertyDetails,
            calculateResult: options.calculationResult,
            regionId: options.designDetailsData.regionId,
            designTypeId: options.designDetailsData.designTypeId,
            projectDesign: options.projectDesign,
            isTemplate: options.templateId != null,
            projectId: options.projectId,
            projectName: options.projectName,
            region: this.dataService.regionsById[options.designDetailsData.regionId],
            commonRegion: (this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find(x => x.id == options.designDetailsData.regionId) as CommonRegion,
            designId: options.designId,
            designName: options.designName,
            templateId: options.templateId,
            templateName: options.templateName,
            templateProjectId: options.templateProjectId,
            templateProjectName: options.templateProjectName,
            designType: designTypesById[options.designDetailsData.designTypeId],
        };

        return designDetails;
    }

    /**
     * Safe to call again before the first request is done. Calls are truncated after first call to not do multiple requests at the same time.
     */
    public async documentServiceUpdateDesign(options: DocumentServiceUpdateDesignOptions): Promise<IDesignListItem | undefined> {
        const design = {
            id: options.designId,
            designName: options.designName,
            projectId: options.projectId,
            metaData: {
                region: options.regionId,
                designType: options.designTypeId,
                standard: options.designStandardId
            },
            projectDesign: options.projectDesign
        } as Design;

        return await this.debounceDocumentService.request(async () => {
            await this.documentService.updateDesignWithNewContentCommon(design, undefined, false, false);
            return this.documentService.findDesignById(options.designId);
        }, options.immediateRequest);
    }

    public async documentServiceCreateDesignTemplate(options: DocumentServiceCreateDesignTemplateOptions): Promise<DesignTemplateEntity> {
        const template: IDesignTemplateDocument = {
            designTypeId: options.designTypeId,
            regionId: options.regionId,
            templateName: options.templateName,
            templateFolderId: options.templateProjectId,
            projectDesign: JSON.stringify(options.projectDesign),
            // TODO FILIP: this should be a generic object that you can pass anything inside (similar to design.metaData)
            designStandardId: options.designStandardId,
            anchorName: '',
            approvalNumber: ''
        };

        const templateId = await this.designTemplateService.create(template);
        return this.designTemplateService.findById(templateId);
    }

    /**
     * Safe to call again before the first request is done. Calls are truncated after first call to not do multiple requests at the same time.
     */
    public async documentServiceUpdateDesignTemplate(options: DocumentServiceUpdateDesignTemplateOptions): Promise<DesignTemplateEntity | undefined> {
        const template: IDesignTemplateDocument = {
            designTemplateDocumentId: options.templateId,
            templateFolderId: options.templateProjectId,
            designTypeId: options.designTypeId,
            regionId: options.regionId,
            templateName: options.templateName,
            projectDesign: JSON.stringify(options.projectDesign),
            // TODO FILIP: this should be a generic object that you can pass anything inside (similar to design.metaData)
            designStandardId: options.designStandardId,
            anchorName: '',
            approvalNumber: ''
        };

        return await this.debounceDocumentService.request(async () => {
            await this.designTemplateService.update(template);
            return this.designTemplateService.findById(options.templateId);
        }, options.immediateRequest);
    }

    public async trackOnDesignOrTemplateChange(options: TrackOnDesignChangeOptions): Promise<void> {
        return await this.debounceTracking.request(async () => {
            if (options.designDetails.isTemplate) {
                await this.trackingService.trackOnTemplateChange(options.designDetails, options.trackingDetails);
            }
            else {
                await this.trackingService.trackOnDesignChange(options.designDetails, options.trackingDetails);
            }
        }, options.immediateRequest);
    }

    public async documentServiceUpdateDesignOrDesignTemplate(options: DocumentServiceUpdateDesignOrDesignTemplateOptions): Promise<IDesignListItem | DesignTemplateEntity | undefined> {
        if (options.designId != null && options.designName != null && options.projectId != null) {
            return await this.documentServiceUpdateDesign({
                designId: options.designId,
                designName: options.designName,
                projectId: options.projectId,
                projectDesign: options.projectDesign,
                designStandardId: options.designStandardId,
                designTypeId: options.designTypeId,
                regionId: options.regionId,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null && options.templateName != null) {
            return await this.documentServiceUpdateDesignTemplate({
                templateId: options.templateId,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId,
                projectDesign: options.projectDesign,
                designStandardId: options.designStandardId,
                designTypeId: options.designTypeId,
                regionId: options.regionId,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId/designName/projectId or templateId/templateName/templateProjectId');
        }
    }

    public isCalculationValid(calculateResult: CalculationResult | undefined): boolean {
        return calculateResult?.calculationStatus == CalculationStatus.OK;
    }

    public checkForConversionChanges(origDesign: ProjectDesign, convertedDesign: ProjectDesign): string {
        const propsOrig = this.convertToMap(origDesign);
        const propsConverted = this.convertToMap(convertedDesign);
        return this.recursiveConversionCheck(0, propsOrig!, propsConverted!);
    }

    public getProjectName(projectId: string): string {
        // TODO FILIP: fix getDisplayName input interface
        return this.documentService.findProjectById(projectId).getDisplayName(this.localizationService as unknown as LocalizationServiceBase)!;
    }

    public getTemplateProjectName(templateProjectId: string | undefined): string {
        // templateProjectId is null when template project is root
        if (templateProjectId == null) {
            return this.localizationService.getString('Agito.Hilti.Profis3.Main.TemplateProjectName');
        }

        return this.designTemplateService.findTemplateFolderById(templateProjectId).name;
    }

    /**
     * Caution: Directly adding HTML through TypeScript can potentially lead to Cross-Site Scripting (XSS) vulnerabilities.
     * A task is planned to migrate this functionality into an Angular component, which will ensure proper HTML escaping.
     */
    private recursiveConversionCheck(depth: number, original: Map<string, unknown>, converted: Map<string, unknown>): string {
        if (depth > 10)
            return '';

        let report = '';

        for (const [propName, convertedPropValue] of converted.entries()) {

            // Converted property not found in imported design file
            if (!original.has(propName)) {
                continue;
            }

            const originalPropValue = original.get(propName);

            // Property is in both imported and converted design. compare values
            if (!isEqual(convertedPropValue, originalPropValue)) {
                report += this.recursiveConversion(depth, convertedPropValue, originalPropValue, propName);
            }
        }

        return report.length == 0 ? report : `<ul>` + report + `</ul>`;
    }

    private recursiveConversion(depth: number, convertedPropValue: unknown, originalPropValue: unknown, propName: string) {
        let report = '';

        // In case of an array we need to check each element
        if (Array.isArray(convertedPropValue)) {
            const convertedArray = (convertedPropValue as unknown[]);
            const originalArray = (originalPropValue as unknown[]);

            for (let i = 0; i < convertedArray.length; i++) {
                const convertedSubMap = this.convertToMap(convertedArray[i]);
                const originalSubMap = this.convertToMap(originalArray[i]);

                if (!isEqual(convertedSubMap, originalSubMap)) {
                    report += this.addToChangeReport(convertedSubMap, originalSubMap, propName, depth, `${(i + 1)}`);
                }
            }
        } else {
            const convertedSubMap = this.convertToMap(convertedPropValue);
            const originalSubMap = this.convertToMap(originalPropValue);
            report += this.addToChangeReport(convertedSubMap, originalSubMap, propName, depth);
        }

        return report;
    }

    private addToChangeReport(convertedSubMap: Map<string, unknown> | null, originalSubMap: Map<string, unknown> | null, propName: string, depth: number, append = '') {
        let report = '';
        if (convertedSubMap && originalSubMap && convertedSubMap.size > 0 && originalSubMap.size > 0) {
            const child = this.recursiveConversionCheck(depth + 1, originalSubMap!, convertedSubMap);
            if (child.length > 0) {
                report += `<li>${this.getDesignPropTranslation(propName)} ${append}</li>`;
                report += `<ul>`;
                report += child;
                report += `</ul>`;
            }
        } else {
            report += `<li>${this.getDesignPropTranslation(propName)}</li>`;
        }
        return report;
    }

    private convertToMap(object: unknown) {
        const list = new Map<string, unknown>();

        try {
            const keys = Object.entries(object as object);
            for (const key in keys) {
                const rec = keys[key];
                list.set(rec[0], rec[1]);
            }
        } catch (_) {
            return null;
        }

        return list;
    }

    private getDesignPropTranslation(propName: string) {
        if (!this.designPropertiesTranslationKeys.has(propName)) {
            return propName;
        }
        const tranKey = this.designPropertiesTranslationKeys.get(propName);
        return tranKey === '' ? propName : this.localizationService.getString(tranKey as string);
    }

    private logChangeRequest(properties: PropertyIdValue[]) {
        if (properties != null && properties.length != 0) {
            console.groupCollapsed('Calculate');
            properties.forEach(element => {
                console.log(element.propertyId + ': %O', element.propertyValue);
            });
            console.groupEnd();
        }
    }

    private logChangeResponse(changes?: Change[]) {
        console.groupCollapsed('Update');

        for (const element of changes ?? []) {
            console.log(element.name + ': %O => %O', element.oldValue, element.newValue);
        }

        console.groupEnd();
    }
}
