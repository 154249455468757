import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
    IModuleInitialData, IModulePreInitialData, IServiceNameMapping, IUserSettingsInfo,
    UserSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import { ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CommonTrackingServiceBase
} from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import {
    FeaturesVisibilityInfoServiceBase
} from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { MenuServiceBase } from '@profis-engineering/pe-ui-common/services/menu.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import {
    RegionOrderServiceBase
} from '@profis-engineering/pe-ui-common/services/region-order.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';
import { TourServiceBase } from '@profis-engineering/pe-ui-common/services/tour.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { BrowserService } from '../../services/browser.service';
import { ChangesService } from '../../services/changes.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DataService } from '../../services/data.service';
import {
    DesignTemplateService, DesignTemplateServiceBaseInternal
} from '../../services/design-template.service';
import { DocumentService, DocumentServiceBaseInternal } from '../../services/document.service';
import { DotnetService } from '../../services/dotnet.service';
import { FavoritesService, FavoritesServiceBaseInternal } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import {
    FeatureVisibilityService, FeatureVisibilityServiceBaseInternal
} from '../../services/features-visibility.service';
import { ImportService } from '../../services/import.service';
import { InitialDataService } from '../../services/initial-data.service';
import { LocalizationService } from '../../services/localization.service';
import { MathService } from '../../services/math.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { RegionOrderService } from '../../services/region-order.service';
import {
    ReportTemplateService, ReportTemplateServiceBaseInternal
} from '../../services/report-template.service';
import { RoutingService } from '../../services/routing.service';
import {
    SharedEnvironmentData, SharedEnvironmentService
} from '../../services/shared-environment.service';
import { SpApiService, SpApiType } from '../../services/sp-api.service';
import { TooltipService } from '../../services/tooltip.service';
import { TourService } from '../../services/tour.service';
import { UnitService } from '../../services/unit.service';
import {
    UserSettings, UserSettingsService, UserSettingsSP
} from '../../services/user-settings.service';
import { InternalDesign, UserService } from '../../services/user.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiInitComponent implements OnInit {
    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;

    @Input()
    public userSettingsServiceBase!: UserSettingsServiceBase<UserSettings>;

    @Input()
    public localizationServiceBase!: LocalizationServiceBase;

    @Input()
    public apiServiceBase!: ApiServiceBase;

    @Input()
    public commonCodeListServiceBase!: CommonCodeListServiceBase;

    @Input()
    public commonTrackingServiceBase!: CommonTrackingServiceBase;

    @Input()
    public designTemplateServiceBase!: DesignTemplateServiceBaseInternal;

    @Input()
    public documentServiceBase!: DocumentServiceBaseInternal;

    @Input()
    public favoritesServiceBase!: FavoritesServiceBaseInternal;

    @Input()
    public modalServiceBase!: ModalServiceBase;

    @Input()
    public numberServiceBase!: NumberServiceBase;

    @Input()
    public routingServiceBase!: RoutingServiceBase;

    @Input()
    public tourServiceBase!: TourServiceBase;

    @Input()
    public userServiceBase!: UserServiceBase<InternalDesign>;

    @Input()
    public unitServiceBase!: UnitServiceBase;

    @Input()
    public menuServiceBase!: MenuServiceBase;

    @Input()
    public importServiceBase!: ImportServiceBase;

    @Input()
    public browserServiceBase!: BrowserServiceBase;

    @Input()
    public changesServiceBase!: ChangesServiceBase;

    @Input()
    public featuresVisibilityInfoServiceBase!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public mathServiceBase!: MathServiceBase;

    @Input()
    public tooltipServiceBase!: TooltipServiceBase;

    @Input()
    public featureVisibilityServiceBase!: FeatureVisibilityServiceBaseInternal;

    @Input()
    public reportTemplateServiceBase!: ReportTemplateServiceBaseInternal;

    @Input()
    public regionOrderServiceBase!: RegionOrderServiceBase;

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData>();

    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    @Output()
    public update = new EventEmitter<IModuleInitialData>();

    constructor(
        private initialDataService: InitialDataService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private userSettingsService: UserSettingsService,
        private localizationService: LocalizationService,
        private apiService: ApiService,
        private commonCodeListService: CommonCodeListService,
        private commonTrackingService: CommonTrackingService,
        private designTemplateService: DesignTemplateService,
        private documentService: DocumentService,
        private favoritesService: FavoritesService,
        private modalService: ModalService,
        private numberService: NumberService,
        private routingService: RoutingService,
        private tourService: TourService,
        private userService: UserService,
        private dataService: DataService,
        private unitService: UnitService,
        private menuService: MenuService,
        private importService: ImportService,
        private browserService: BrowserService,
        private changesService: ChangesService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private mathService: MathService,
        private tooltipService: TooltipService,
        private dotnetService: DotnetService,
        private spApiService: SpApiService,
        private featureVisibilityService: FeatureVisibilityService,
        private reportTemplateService: ReportTemplateService,
        private regionOrderService: RegionOrderService,
        private applicationProviderService: ApplicationProviderService,
    ) { }

    public ngOnInit(): void {
        const preInitData = this.getPreInitialData();

        // after all the initial data is loaded (pe-ui and sp-ui) init services in order and then notify pe-ui with init to render
        preInitData.initialDataLoadedEvent = async () => {
            // we need feature service set to check feature flag status
            this.featureVisibilityService.setBaseService(this.featureVisibilityServiceBase);

            // skip init and preInit emit if all SP modules are disabled
            const anyShearModuleEnabled =
                this.featureVisibilityService.isFeatureEnabled('SP_ContentFeature_Strength') ||
                this.featureVisibilityService.isFeatureEnabled('SP_ContentFeature_Punch');
            if (!anyShearModuleEnabled) {
                return;
            }

            this.preInitServices();
            await this.loadData();
            this.initServices();

            // TODO - update event should be removed once necessary fixes (module sending localization extension for initial data translation)
            // in pe-ui are done
            this.localizationService.localizationChange.subscribe(() => {
                this.applicationProviderService.updateDesignTypeTranslations();
                this.update.emit(this.initialDataService.updateModuleData());
            });

            this.init.emit(this.initialDataService.getInitialData());
        };

        this.preInit.emit(preInitData);
    }

    /** Called before data is loaded */
    private preInitServices() {
        this.connectBaseServices();

        if (environment.webassembly) {
            this.spApiService.type = SpApiType.webassembly;
        }
    }

    /** Called after data is loaded */
    private initServices() {
        this.userSettingsService.init(this.dataService);
        this.menuService.init();
        this.sharedEnvironmentService.init(this.sharedEnvironmentData);
    }

    private async loadData() {
        const loaded = await Promise.allSettled([
            this.dataService.loadData(),
            this.localizationService.loadTranslations(),
        ]);

        for (const result of loaded) {
            if (result.status === 'rejected') {
                throw result.reason;
            }
        }
    }

    private connectBaseServices(): void {
        this.localizationService.setBaseService(this.localizationServiceBase);
        this.apiService.setBaseService(this.apiServiceBase);
        this.commonCodeListService.setBaseService(this.commonCodeListServiceBase);
        this.commonTrackingService.setBaseService(this.commonTrackingServiceBase);
        this.designTemplateService.setBaseService(this.designTemplateServiceBase);
        this.documentService.setBaseService(this.documentServiceBase);
        this.favoritesService.setBaseService(this.favoritesServiceBase);
        this.modalService.setBaseService(this.modalServiceBase);
        this.numberService.setBaseService(this.numberServiceBase);
        this.routingService.setBaseService(this.routingServiceBase);
        this.tourService.setBaseService(this.tourServiceBase);
        this.userService.setBaseService(this.userServiceBase);
        this.userSettingsService.setBaseService(this.userSettingsServiceBase);
        this.unitService.setBaseService(this.unitServiceBase);
        this.menuService.setBaseService(this.menuServiceBase);
        this.importService.setBaseService(this.importServiceBase);
        this.browserService.setBaseService(this.browserServiceBase);
        this.changesService.setBaseService(this.changesServiceBase);
        this.featuresVisibilityInfoService.setBaseService(this.featuresVisibilityInfoServiceBase);
        this.mathService.setBaseService(this.mathServiceBase);
        this.tooltipService.setBaseService(this.tooltipServiceBase);
        this.reportTemplateService.setBaseService(this.reportTemplateServiceBase);
        this.regionOrderService.setBaseService(this.regionOrderServiceBase);
    }

    private getPreInitialData(): IModulePreInitialData {
        // will be called even if module is disabled
        return {
            // Common
            authenticationRequiredServices: this.getAuthenticationRequiredServices(),
            serviceNameMapping: this.getServiceNameMapping(),
            userSettingsInfo: this.getUserSettingsInfo()
        };
    }

    private getAuthenticationRequiredServices(): string[] {
        // will be called even if module is disabled
        const authentication: string[] = [
            environment.htmlReportServiceUrl
        ];

        // this is required only when not using webassembly for application
        if (!environment.webassembly) {
            authentication.push(
                environment.designServiceUrl,
                environment.calculationServiceUrl,
                environment.reportServiceUrl
            );
        }

        return authentication;
    }

    private getServiceNameMapping(): IServiceNameMapping {
        // will be called even if module is disabled
        const mapping: IServiceNameMapping = {};

        mapping[environment.htmlReportServiceUrl] = 'pe-html-report';

        // this is required only when not using webassembly for application
        if (!environment.webassembly) {
            mapping[environment.designServiceUrl] = 'sp-design-service';
            mapping[environment.calculationServiceUrl] = 'sp-calculation-service';
            mapping[environment.reportServiceUrl] = 'sp-report-service';
        }

        return mapping;
    }

    /** user settings definition */
    private getUserSettingsInfo(): IUserSettingsInfo {
        // will be called even if module is disabled
        return {
            userSettingsKey: 'sp',
            userSettings: new UserSettingsSP(
                // saved only as reference/used later
                this.dataService,
                this.userSettingsService
            ) as unknown as UserSettingsSection
        };
    }
}
