import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { FloatArray } from '@babylonjs/core/types';
import isEqual from 'lodash-es/isEqual';
import { PunchComponent, PunchComponentConstructor } from '../punch-component';
import { PunchBaseMaterialModel, VisibilityModel } from '../punch-gl-model';
import { baseMaterialAlphaIndex } from './alpha-index-helper';

interface PunchBaseMaterialModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
}

export const baseMaterialColor = Color3.FromHexString('#cbcabf');
export const baseMaterialDarkColor = Color3.FromHexString('#a9a89a');

export class PunchBaseMaterial extends PunchComponent {
    private baseMaterialMaterial!: StandardMaterial;
    private baseMaterialMaterialDark!: StandardMaterial;

    private baseMaterialModelUpdateData!: PunchBaseMaterialModelUpdateData;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);

        this.baseMaterialModelUpdateData = {} as PunchBaseMaterialModelUpdateData;

        this.initMaterial();
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: this.getVisibilityInfos(),
            baseMaterial: this.model.baseMaterial,
        } as PunchBaseMaterialModelUpdateData;

        if (isEqual(this.baseMaterialModelUpdateData, modelUpdateData)) {
            return;
        }
        this.baseMaterialModelUpdateData = modelUpdateData;

        // Ensure the meshes are created
        this.ensureBaseMaterial();
    }

    private getVisibilityInfos(): VisibilityModel {
        return {
            TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
        };
    }
    private initMaterial() {
        this.baseMaterialMaterial = this.cache.materialCache.create('BaseMaterialMaterial', () => new StandardMaterial('BaseMaterialMaterial', this.scene));
        this.baseMaterialMaterial.diffuseColor = baseMaterialColor;

        this.baseMaterialMaterialDark = this.cache.materialCache.create('BaseMaterialMaterialDark', () => new StandardMaterial('BaseMaterialMaterialDark', this.scene));
        this.baseMaterialMaterialDark.diffuseColor = baseMaterialDarkColor;
    }

    private createBox(): Mesh {
        return MeshBuilder.CreateBox('PunchBaseMaterial', {}, this.scene);
    }
    private ensureBaseMaterial() {
        const mesh = this.cache.meshCache.create('PunchBaseMaterialSides', () => this.createBox());
        mesh.setEnabled(true);

        // rotation mesh
        mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        this.setBaseMaterialMeshProperties(mesh);
    }

    private setBaseMaterialMeshProperties(mesh: Mesh) {
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.material = this.baseMaterialMaterial;

        mesh.alphaIndex = baseMaterialAlphaIndex;
        mesh.scaling.x = this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX;
        mesh.scaling.y = this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY;
        mesh.scaling.z = this.model.baseMaterial.thickness;
        // mesh.position = new Vector3(0, 100, 0);

        mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
    }

    private createSidesMesh() {
        const positions = [
            -0.5, -0.5, -0.5, 0.5, -0.5, -0.5, 0.5, 0.5, -0.5,

            -0.5, -0.5, -0.5, 0.5, 0.5, -0.5, -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5, -0.5, -0.5, -0.5, -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5, -0.5, 0.5, -0.5, -0.5, 0.5, 0.5,

            -0.5, -0.5, 0.5, 0.5, 0.5, 0.5, 0.5, -0.5, 0.5,

            -0.5, -0.5, 0.5, -0.5, 0.5, 0.5, 0.5, 0.5, 0.5,

            0.5, -0.5, 0.5, 0.5, 0.5, -0.5, 0.5, -0.5, -0.5,

            0.5, -0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, -0.5,
        ];
        const indices = [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23,
        ];

        return this.createMesh(positions, indices, 'BaseMaterialSides');
    }

    private createMesh(positions: number[], indices: number[], name: string) {
        const mesh = new Mesh(name, this.scene);
        const normals: FloatArray = [];
        const vertexData = new VertexData();

        VertexData.ComputeNormals(positions, indices, normals);

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.applyToMesh(mesh);

        return mesh;
    }

    public override dispose(): void {
        super.dispose();
    }
}
