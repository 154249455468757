import { Component, Input, NgZone, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IAddEditDesignComponent, IDetailedDisplayDesignFromModule } from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType, IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { ISaveDesign, ISaveDesignResult } from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { required } from '../../required';
import { AppUnitPropertyId, DataService, Unit } from '../../services/data.service';
import { DesignService, designTypes, PunchCreateDesignResult, PunchDesignDetails, UpdateDesignResult } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { InternalDesign } from '../../services/user.service';

interface IDetailedDisplayDesignFromModuleInternal extends IDetailedDisplayDesignFromModule {
    isCreateTemplate?: boolean;
}

@Component({
    templateUrl: './add-edit-design-punch.component.html',
    styleUrls: ['./add-edit-design-punch.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignPunchComponent implements IAddEditDesignComponent, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public addEditType!: AddEditType;

    @Input()
    public submitted!: boolean;

    /** isTemplate - only used for AddEditType.add - for AddEditType.edit use this.designDetails.isTemplate */
    @Input()
    public displayDesignType!: DisplayDesignType;

    /**
     * @deprecated use this.designDetails
     */
    @Input()
    public detailedDisplayDesignFromModule?: IDetailedDisplayDesignFromModuleInternal;

    @Input()
    public save = (saveDesign: ISaveDesign) => {
        // TODO FILIP: if this function is only called from pe-ui why if statement???
        // this save function should only be called from pe-ui
        return NgZone.isInAngularZone()
            ? this.saveInternal(saveDesign)
            : this.ngZone.run(() => {
                return this.saveInternal(saveDesign);
            });
    };

    public isLoaded = false;

    public AddEditType = AddEditType;
    public requiredValidator = Validators.required;

    public collapseRegion!: {
        UnitsAndParams: boolean;
        SafetyFactor: boolean;
        ConcreteMaterial: boolean;
        SteelMaterial: boolean;
        InterfaceShearCalculation: boolean;
        MethodAndApprovals: boolean;
    };

    public gammaS: number | null = null;
    public gammaC: number | null = null;
    public alphaCC: number | null = null;
    public etaT = 1;
    public e: number | null = null;
    public Kc: number | null = null;
    public designStandardDropdown!: DropdownProps<number>;
    public stressDropdown!: DropdownProps<UnitType>;
    public lengthDropdown!: DropdownProps<UnitType>;
    public areaDropdown!: DropdownProps<UnitType>;
    public forceDropdown!: DropdownProps<UnitType>;
    public momentDropdown!: DropdownProps<UnitType>;
    public temperatureDropdown!: DropdownProps<UnitType>;
    public forcePerLengthDropdown!: DropdownProps<UnitType>;
    public densityDropdown!: DropdownProps<UnitType>;
    public areaPerLengthDropdown!: DropdownProps<UnitType>;

    constructor (
        private localizationService: LocalizationService,
        private ngZone: NgZone,
        private dataService: DataService,
        private designService: DesignService,
        private modalService: ModalService,
    ) { }

    public get design() {
        return this.detailedDisplayDesignFromModule?.design as InternalDesign | undefined;
    }

    public get designDetails() {
        return this.design?.designDetails as PunchDesignDetails | undefined;
    }

    public get trackingDetails() {
        return this.design?.trackingDetails;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isLoaded) {
            this.collapseRegion = {
                UnitsAndParams: false,
                SafetyFactor: false,
                ConcreteMaterial: true,
                SteelMaterial: true,
                InterfaceShearCalculation: true,
                MethodAndApprovals: false
            };

            this.initControls();

            this.isLoaded = true;
        }

        if (changes['selectedRegionId']?.firstChange === false) {
            this.onRegionChange();
        }
    }

    private initControls() {
        // show design changed popup
        const convertChanges = this.design?.convertChanges;
        if (convertChanges != null) {
            // no await needed
            this.modalService.showConvertWarningDialog(convertChanges)
                .catch(error => console.error(error));

            // clear convert changes so warning popup is shown only once
            this.design!.convertChanges = undefined;
        }

        this.initDesignStandardDropdown();
        this.initUnits();
        this.initSubComponents();
    }

    private onRegionChange() {
        this.setDefaultUnits();
    }

    private initUnits() {
        this.lengthDropdown = this.createUnitDropdown(
            'unitLength',
            'Length',
            this.dataService.units.length,
            this.designDetails?.properties.unitLength
        );

        this.areaDropdown = this.createUnitDropdown(
            'unitArea',
            'Area',
            this.dataService.units.area,
            this.designDetails?.properties.unitArea
        );

        this.stressDropdown = this.createUnitDropdown(
            'unitStress',
            'Stress',
            this.dataService.units.stress,
            this.designDetails?.properties.unitStress
        );

        this.forceDropdown = this.createUnitDropdown(
            'unitForce',
            'Force',
            this.dataService.units.force,
            this.designDetails?.properties.unitForce
        );

        this.momentDropdown = this.createUnitDropdown(
            'unitMoment',
            'Moment',
            this.dataService.units.moment,
            this.designDetails?.properties.unitMoment
        );

        this.temperatureDropdown = this.createUnitDropdown(
            'unitTemperature',
            'Temperature',
            this.dataService.units.temperature,
            this.designDetails?.properties.unitTemperature
        );

        this.forcePerLengthDropdown = this.createUnitDropdown(
            'unitForcePerLength',
            'ForcePerLength',
            this.dataService.units.forcePerLength,
            this.designDetails?.properties.unitForcePerLength
        );

        this.densityDropdown = this.createUnitDropdown(
            'unitDensity',
            'Density',
            this.dataService.units.density,
            this.designDetails?.properties.unitDensity
        );

        this.areaPerLengthDropdown = this.createUnitDropdown(
            'unitAreaPerLength',
            'AreaPerLength',
            this.dataService.units.areaPerLength,
            this.designDetails?.properties.unitAreaPerLength
        );
    }

    private initSubComponents() {
        if (this.addEditType == AddEditType.edit) {
            required(this.designDetails);

            this.alphaCC = this.designDetails.properties.alphaCC;
            this.etaT = this.designDetails.properties.etaT;
            this.e = this.designDetails.properties.e;
            this.gammaS = this.designDetails.properties.gammaS;
            this.gammaC = this.designDetails.properties.gammaC;
            this.Kc = this.designDetails.properties.kc;
        }
        else if (this.addEditType == AddEditType.add) {
            // for textboxes we set the value to undefined and use a placeholder
        }
        else {
            throw new Error('Unknown addEditType');
        }
    }

    private initDesignStandardDropdown() {
        this.designStandardDropdown = {
            id: 'add-edit-design-design-standard-sp-dropdown',
            title: this.localizationService.getString('SP.AddEditDesign.Dropdown.DesignStandard.Title'),
            notSelectedText: this.localizationService.getString('SP.AddEditDesign.Dropdown.DesignStandard.Placeholder'),
            validators: [this.requiredValidator]
        };

        this.setDesignStandardItems();
        this.setDesignStandardValue();
    }

    private setDesignStandardItems() {
        this.designStandardDropdown.items = this.designStandards.map(designStandard => ({
            id: `add-edit-design-design-standard-sp-dropdown-item-${designStandard.id}`,
            value: designStandard.id,
            text: this.localizationService.getString(designStandard.nameKey)
        }));
    }

    private setDesignStandardValue() {
        if (this.addEditType == AddEditType.edit) {
            required(this.designDetails);
            this.designStandardDropdown.selectedValue = this.designDetails.properties.designStandardId;
        }

        this.onDesignStandardDropdownSelectedValueChange(this.designStandardDropdown.selectedValue);
    }

    private createUnitDropdown(property: AppUnitPropertyId, key: string, units: Unit[], selectedUnit?: UnitType): DropdownProps<UnitType> {
        const regionId = this.selectedRegionId;
        return {
            id: 'add-edit-design-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(`Units.${key}`),
            items: units.map(x => ({
                value: x.id,
                text: x.name
            })),
            selectedValue: selectedUnit ?? this.dataService.getPropertyDetail(property, { regionId }).defaultValue
        };
    }

    private translateDropdownTitle(key: string): string {
        return this.localizationService.getString(`SP.AddEditDesign.${key}`);
    }

    private setDefaultUnits() {
        const regionId = this.selectedRegionId;

        this.lengthDropdown.selectedValue = this.dataService.getPropertyDetail('unitLength', { regionId }).defaultValue;
        this.stressDropdown.selectedValue = this.dataService.getPropertyDetail('unitStress', { regionId }).defaultValue;
        this.areaDropdown.selectedValue = this.dataService.getPropertyDetail('unitArea', { regionId }).defaultValue;
        this.forceDropdown.selectedValue = this.dataService.getPropertyDetail('unitForce', { regionId }).defaultValue;
        this.momentDropdown.selectedValue = this.dataService.getPropertyDetail('unitMoment', { regionId }).defaultValue;
        this.temperatureDropdown.selectedValue = this.dataService.getPropertyDetail('unitTemperature', { regionId }).defaultValue;
        this.forcePerLengthDropdown.selectedValue = this.dataService.getPropertyDetail('unitForcePerLength', { regionId }).defaultValue;
        this.densityDropdown.selectedValue = this.dataService.getPropertyDetail('unitDensity', { regionId }).defaultValue;
        this.areaPerLengthDropdown.selectedValue = this.dataService.getPropertyDetail('unitAreaPerLength', { regionId }).defaultValue;
    }

    public getDesignStandard(): number {
        return this.designStandardDropdown.selectedValue!;
    }

    public onDesignStandardDropdownSelectedValueChange(designStandardId?: number) {
        if (this.designStandardDropdown.selectedValue != null && designStandardId == this.designStandardDropdown.selectedValue && designStandardId in this.allowedDesignStandardIds) {
            return;
        }

        if (designStandardId != null && designStandardId in this.allowedDesignStandardIds) {
            this.designStandardDropdown.selectedValue = designStandardId;
        }
        else {
            this.designStandardDropdown.selectedValue = this.dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.punch.id, regionId: 0 }).defaultValue;
        }
    }

    private get designStandards() {
        return this.dataService.designStandards.filter(x => this.allowedDesignStandardIds.includes(x.id));
    }

    private get allowedDesignStandardIds() {
        return this.dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.punch.id, regionId: this.selectedRegionId }).allowedValues ?? [];
    }

    private get etaTValues() {
        return this.dataService.etaTValues;
    }

    public get selectedDesignStandardDescription() {
        const key = this.dataService.designStandardsById[this.designStandardDropdown.selectedValue as number]?.descriptionKey;
        return key != null ? this.localizationService.getString(key) : null;
    }

    public isSIADesignStandard(designStandardId: number | undefined) {
        return designStandardId == 2;
    }
    private async saveInternal(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        if (this.addEditType == AddEditType.add) {
            try {
                // TODO TEAM: fix common to correctly pass the is template value
                const isTemplate = this.displayDesignType == DisplayDesignType.template ||
                    this.detailedDisplayDesignFromModule?.displayDesignType == DisplayDesignType.template ||
                    this.detailedDisplayDesignFromModule?.isCreateTemplate === true;

                // setTimeout so we don't block the UI loading indicator
                const { designDetails, trackingDetails } = await new Promise<PunchCreateDesignResult>(resolve => setTimeout(() => resolve(this.designService.punchCreateDesignOrDesignTemplate({
                    designName: !isTemplate ? saveDesign.designName : undefined,
                    projectId: !isTemplate ? saveDesign.projectId : undefined,

                    templateName: isTemplate ? saveDesign.designName : undefined,
                    templateProjectId: isTemplate ? saveDesign.templateFolderId : undefined,

                    designTypeId: designTypes.punch.id,
                    regionId: this.selectedRegionId,
                    designStandardId: this.designStandardDropdown.selectedValue!,
                    reportLanguageId: this.localizationService.selectedLanguageLCID(),

                    unitLength: this.lengthDropdown.selectedValue!,
                    unitArea: this.areaDropdown.selectedValue!,
                    unitStress: this.stressDropdown.selectedValue!,
                    unitAreaPerLength: this.areaPerLengthDropdown.selectedValue!,
                    unitDensity: this.densityDropdown.selectedValue!,
                    unitForce: this.forceDropdown.selectedValue!,
                    unitForcePerLength: this.forcePerLengthDropdown.selectedValue!,
                    unitMoment: this.momentDropdown.selectedValue!,
                    unitTemperature: this.temperatureDropdown.selectedValue!,

                    alphaCC: this.alphaCC,
                    etaT: this.etaT,
                    e: this.e,
                    gammaC: this.gammaC,
                    gammaS: this.gammaS,
                    Kc: this.Kc,
                }))));

                const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

                return {
                    designId: designDetails.isTemplate ? designDetails.templateId! : designDetails.designId!,
                    path: `${UrlPath.main}`,
                    design: peDesignObject,
                    detailedDisplayDesign: designDetails as unknown as IDetailedDisplayDesign,
                    success: true
                };
            }
            catch (error) {
                console.error(error);

                return {
                    designId: '',
                    path: '',
                    design: undefined as unknown as Design,
                    success: false
                };
            }
        }
        else if (this.addEditType == AddEditType.edit) {
            // setTimeout so we don't block the UI loading indicator
            const updateDesignResult = (await new Promise<UpdateDesignResult | undefined>(resolve => setTimeout(() => {
                required(this.designDetails);
                required(this.trackingDetails);

                resolve(this.designService.punchUpdateDesignOrDesignTemplate({
                    designId: !this.designDetails.isTemplate ? this.designDetails.designId! : undefined,
                    designName: !this.designDetails.isTemplate ? saveDesign.designName : undefined,
                    projectId: !this.designDetails.isTemplate ? saveDesign.projectId : undefined,

                    templateId: this.designDetails.isTemplate ? this.designDetails.templateId! : undefined,
                    templateName: this.designDetails.isTemplate ? saveDesign.designName : undefined,
                    templateProjectId: this.designDetails.isTemplate ? saveDesign.templateFolderId : undefined,

                    trackingDetails: this.trackingDetails,

                    projectDesign: this.designDetails.projectDesign,
                    properties: [
                        { propertyId: 'alphaCC', propertyValue: this.alphaCC },
                        { propertyId: 'etaT', propertyValue: this.etaT },
                        { propertyId: 'e', propertyValue: this.e },
                        { propertyId: 'gammaC', propertyValue: this.gammaC },
                        { propertyId: 'gammaS', propertyValue: this.gammaS },
                        { propertyId: 'kc', propertyValue: this.Kc },
                        { propertyId: 'unitLength', propertyValue: this.lengthDropdown.selectedValue! },
                        { propertyId: 'unitArea', propertyValue: this.areaDropdown.selectedValue! },
                        { propertyId: 'unitStress', propertyValue: this.stressDropdown.selectedValue! },
                        { propertyId: 'unitAreaPerLength', propertyValue: this.areaPerLengthDropdown.selectedValue! },
                        { propertyId: 'unitDensity', propertyValue: this.densityDropdown.selectedValue! },
                        { propertyId: 'unitForce', propertyValue: this.forceDropdown.selectedValue! },
                        { propertyId: 'unitForcePerLength', propertyValue: this.forcePerLengthDropdown.selectedValue! },
                        { propertyId: 'unitMoment', propertyValue: this.momentDropdown.selectedValue! },
                        { propertyId: 'unitTemperature', propertyValue: this.temperatureDropdown.selectedValue! },
                    ],

                    immediateRequest: true
                }));
            })))!;

            const designDetails = updateDesignResult.designDetails!;
            const peDesignObject = this.detailedDisplayDesignFromModule?.design as Design;
            this.designService.updatePeDesignObject(peDesignObject, designDetails);

            return {
                designId: designDetails.isTemplate ? designDetails.templateId! : designDetails.designId!,
                path: `${UrlPath.main}`,
                design: peDesignObject,
                detailedDisplayDesign: designDetails as unknown as IDetailedDisplayDesign,
                success: true
            };
        }
        else {
            throw new Error('Unknown AddEditType');
        }
    }

    public getEtaTValues() {
        return this.etaTValues.map(item => {
            return {
                value: item,
                text: item.toString()
            };
        }).sort((a, b) => String(a.text[0]).localeCompare(b.text[0]));
    }
}
