import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import isEqual from 'lodash-es/isEqual';
import {
    PunchComponent,
    PunchComponentConstructor
} from '../../punch-component';
import { PunchBaseMaterialModel, VisibilityModel } from '../../punch-gl-model';
import { baseMaterialAlphaIndex } from '../alpha-index-helper';
import { baseMaterialDarkColor } from '../punch-base-material';
import { getPunchSpanOffsetX, getPunchSpanOffsetY } from './punch-helpers';

interface PunchCompressionMemberModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
    lengthInX: number;
    widthInY: number;
}

export class CompressionMemberElement extends PunchComponent {
    private compressionMaterial!: StandardMaterial;
    private compressionMeshes: Mesh[];
    private compressionMemberModelUpdateData: PunchCompressionMemberModelUpdateData;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.compressionMeshes = new Array<Mesh>();
        this.compressionMemberModelUpdateData =
            {} as PunchCompressionMemberModelUpdateData;

        this.initMaterial();
    }

    private initMaterial() {
        this.compressionMaterial = new StandardMaterial(
            'PostInstalledElementMaterial',
            this.scene
        );
        this.compressionMaterial.diffuseColor = baseMaterialDarkColor;
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: {
                TransparentConcrete:
                    this.model.visibilityModel.TransparentConcrete,
            },
            baseMaterial: this.model.baseMaterial,
            lengthInX: this.model.baseMaterial.punchLength,
            widthInY: this.model.baseMaterial.punchWidth,
        } as PunchCompressionMemberModelUpdateData;

        if (isEqual(this.compressionMemberModelUpdateData, modelUpdateData)) {
            return;
        }
        this.compressionMemberModelUpdateData = modelUpdateData;
        this.ensureMesh();
    }

    private ensureMesh() {
        const mesh = this.cache.meshCache.create('CompressionMemberMesh', () =>
            this.createBox()
        );
        mesh.setEnabled(true);

        // rotation mesh
        mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(
            this.model.baseMaterial.baseMemberId
        );

        this.setCompressionMembersMeshProperties(mesh);
    }

    private createBox(): Mesh {
        const mesh = MeshBuilder.CreateBox('PunchBaseMaterial', {}, this.scene);

        return mesh;
    }

    private setCompressionMembersMeshProperties(mesh: Mesh) {
        this.compressionMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;

        mesh.material = this.compressionMaterial;
        mesh.alphaIndex = baseMaterialAlphaIndex;

        const compressionHeight = 1000;
        mesh.scaling.x = this.model.baseMaterial.punchLength;
        mesh.scaling.y = this.model.baseMaterial.punchWidth;
        mesh.scaling.z = compressionHeight;

        mesh.position = new Vector3(
            getPunchSpanOffsetX(this.model),
            getPunchSpanOffsetY(this.model),
            compressionHeight / 2 + this.model.baseMaterial.thickness / 2
        );
        mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(
            this.model.baseMaterial.baseMemberId
        );
        mesh.isVisible = true;
    }
}
