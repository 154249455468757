import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_PostInstalledElement(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled',
        Id: 4,
        Name: 'postinstalled-tab',
        Image: 'tab-supplementary-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-supplementary-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_PostInstalledElement_ShearReinforcement(),
            createSPMenu_PostInstalledElement_ReinforcementPosition(),
        ]
    };
}

function createSPMenu_PostInstalledElement_ShearReinforcement(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement',
        Id: 40,
        Name: 'strengthening-element-section',
        Controls: [
            {
                ControlType: 'PopupGrid',
                Name: 'postinstalled-family-popup-grid',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family',
                CodelistName: 'fastenerFamilyGroups',
                UIPropertyId: 'fastenerFamilyGroupId',
                WrapName: true
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-type-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type',
                CodelistName: 'fastenerFamilies',
                UIPropertyId: 'fastenerFamilyId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-size-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size',
                CodelistName: 'fasteners',
                UIPropertyId: 'fastenerId'
            }
        ]
    };
}

function createSPMenu_PostInstalledElement_ReinforcementPosition(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition',
        Id: 41,
        Name: 'reinforcement-position-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.DefineStrengtheningElement',
                Name: 'define-strenghtening-element',
                UIPropertyId: 'defineStrengtheningElement'
            },
            {
                ControlType: 'Dropdown',
                Name: 'installation-direction-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'punchInstallationDirectionId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'reinforcement-arrangement-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.ReinforcementArrangement',
                CodelistName: 'reinforcementArrangements',
                UIPropertyId: 'reinforcementArrangementId'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-x',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiX',
                UIPropertyId: 'radiiX'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-y',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiY',
                UIPropertyId: 'radiiY'
            },
            {
                ControlType: 'TextBox',
                Name: 'radii-corners',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.RadiiCorners',
                UIPropertyId: 'radiiCorners'
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-first-perimeter',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingFirstPerimeter',
                UIPropertyId: 'firstPerimeterSpacing'
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing-between-subsequent-perimeters',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.SpacingBetweenSubsequentPerimeters',
                UIPropertyId: 'subsequentPerimeterSpacing'
            },
            {
                ControlType: 'TextBox',
                Name: 'element-number-per-radii',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.ReinforcementPosition.NumberOfElementsPerRadii',
                UIPropertyId: 'radiiElementNumber'
            },
        ]
    };
}
