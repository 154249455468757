import { PunchBaseUpdate, PunchModel } from '../../../web-gl/punch-gl-model';
import { punchPropertyValueChanged } from '../update';

export class PunchBaseMaterialUpdate extends PunchBaseUpdate {
    @punchPropertyValueChanged('baseMemberId')
    protected updateBaseMemberId(baseMemberId: number, model: PunchModel) {
        model.baseMaterial.baseMemberId = baseMemberId;
    }

    @punchPropertyValueChanged('punchLength')
    protected updatePunchLength(punchLength: number, model: PunchModel) {
        model.baseMaterial.punchLength = punchLength;
    }

    @punchPropertyValueChanged('punchWidth')
    protected updatePunchWidth(punchWidth: number, model: PunchModel) {
        model.baseMaterial.punchWidth = punchWidth;
    }

    @punchPropertyValueChanged('spanNegX')
    protected update(spanNegX: number, model: PunchModel) {
        model.baseMaterial.spanNegX = spanNegX;
    }
    @punchPropertyValueChanged('spanPosX')
    protected updateSpanPosX(spanPosX: number, model: PunchModel) {
        model.baseMaterial.spanPosX = spanPosX;
    }

    @punchPropertyValueChanged('spanNegY')
    protected updateSpanNegY(spanNegY: number, model: PunchModel) {
        model.baseMaterial.spanNegY = spanNegY;
    }
    @punchPropertyValueChanged('spanPosY')
    protected updateSpanPosY(spanPosY: number, model: PunchModel) {
        model.baseMaterial.spanPosY = spanPosY;
    }

    @punchPropertyValueChanged('thickness')
    protected updateThickness(thickness: number, model: PunchModel) {
        model.baseMaterial.thickness = thickness;
    }
}
